import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CartState } from "../Context/Context";
import { OrderState } from "../Context/OrderContext";

import { Button, Container, Grid, makeStyles, Typography, Hidden, Box, Radio, Modal, Backdrop, Fade, Divider, Dialog, DialogActions, DialogContent, DialogTitle, Input, InputLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Rating  from "../components/Rating";
import OrderList from "../components/OrderList";
import { PaystackButton } from "react-paystack";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ErrorMessage from "../components/ErrorMessage";


const useStyles = makeStyles((theme) => ({
  root: {
   minHeight: '100vh',

  },
  container: {
   display: 'flex',
   flexDirection: 'column',
  },
  check: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      backgroundColor:'#fff',
    },
  },
   text:{
    color:'#000',
   },
  info:{
    display: 'flex',
    flexDirection: 'column',
  },
  tot:{
   backgroundColor:'white',
   color:'black',
   display:'flex',
   justifyContent:'space-between',
   marginTop:'10px',
  },
  hide:{
   paddingLeft:'20px',
   paddingRight:'20px',
   width:'100%'
  },
  hd:{
   display:'flex',
   justifyContent: 'space-between',
   marginTop:'10px',
  },
  change:{
   color:'blue'
  },
  addr:{
   backgroundColor:'#fff',
   borderRadius:'5px',
   color:'#000', 
   padding:'5px',
   marginBottom:'10px',
  },
  sel:{
   display:'flex'
  },
  pick:{
   display:'flex',
   justifyContent:'space-between',
   padding:'10px',
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey'
  },
  point:{
   padding:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },
  ship:{
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey',
   padding:'10px'
  },
  orderlist:{
   
   paddingLeft:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    display: "flex",
    width: "35%",
    height: "75%",
    backgroundColor: "#fff",
    border: "1px solid #282c34",
    borderRadius: 5,
    color: "black",
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      height:'100%',
    },
  },
  options:{
   height:'100%',
   width:'25%',
   backgroundColor:'#dcdcdc',
   [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  list:{
   display:'flex',
   padding:'10px',
   alignItems:'center',
  },
  title:{
   display:'flex',
   justifyContent:'space-between',
   alignItems:'center',
   paddingBottom:'3px',
  },
  logo:{
   width:'60px',
   height:'50px',
  },
  number:{
   width:'75%',
   padding:'10px',
   [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  details:{
   backgroundColor:'#dcdcdc',
   borderRadius:'7px',
   padding:'20px',
  },
}));


const config= {
 reference: (new Date()).getTime().toString(),
 email: "user@gmail.com",
 amount: 20000,
 publicKey:'pk',
};


const Summary = ( ) =>{
      const classes = useStyles();
      const history = useHistory();
      const location = useLocation();

      useEffect(() => {
      window.scroll(0, 0);
      // eslint-disable-next-line
    }, [history]);

      const { state: { cart },
        dispatch,
        items,
        amount,
        paystack,
        direct,
        transport,
      } = CartState();
      
      const {ordState, ordDispatch, store}= OrderState();

      const { info, loading, error }= ordState;

      const [open, setOpen] = useState(false);

      const [selectedFile, setSelectedFile] = useState(null);
      const [clicked, setClicked] = useState(false);
      const [diaOpen, setDiaOpen]= useState(false);
      const[ total, setTotal] = useState();
      const [picMessage, setPicMessage] = useState(null);
      const [pic, setPic] = useState(
       "https://icon-library.com/images/sack-icon/sack-icon-22.jpg"
     );

      useEffect(() => {
         setTotal(cart.reduce((acc, curr)=> acc + Number(curr.price) * curr.qty,0));
       
       }, [cart]);

       const deposited = total + transport;
       const address= JSON.parse(localStorage.getItem("Delivery"));
     

      const postDetails = (pics) => {
    if (
      pics ===
      "https://icon-library.com/images/sack-icon/sack-icon-22.jpg"
    ) {
      return setPicMessage("Please Select an Image");
    }
    setPicMessage(null);
    if (pics.type === "image/jpeg" || pics.type === "image/png") {
   const data = new FormData();
   data.append("file", pics);
  data.append("upload_preset", "adijastore");
  data.append("cloud_name", "diskcdxtg");
  fetch("https://api.cloudinary.com/v1_1/diskcdxtg/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setPic(data.url.toString());
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return setPicMessage("Please Select an Image");
    }
  };


      const submitHandler = async (e) => {
        e.preventDefault();
        setClicked(true);
        let txnref;
      
         ordDispatch(store(items, address, deposited, txnref, pic));
        handleClose();
        closeDia();

      };

      const handlePaystackSuccessAction = (reference)=> {
       alert(reference);
      };
      const handlePaystackCloseAction= () =>{
       alert('closed');
      };
      const componentProps ={
       ...config,
       text:'PAY NOW',
       onSuccess:(reference) => handlePaystackSuccessAction(reference),
       onClose: handlePaystackCloseAction,
      };

      const handleOpen = () => {
       setOpen(true);
      };

     const handleClose = () => {
      setOpen(false);
     };

     const handleDia= () => {
      setDiaOpen(true);
     };

     const closeDia= () =>{
      setDiaOpen(false);
     };

     const handleBoth = () => {
      handleClose();
      closeDia();
     };

     const userInfo = JSON.parse(localStorage.getItem("userInfo"));

       useEffect(() => {
        if (!userInfo) {
          const currentPath = location.pathname;
          localStorage.setItem('redirectPath', currentPath);
          history.push('/account/login');
        }
       }, [userInfo, history, location]);
       
       const Firstname= userInfo.firstname;
       const Lastname= userInfo.lastname;
       const Address= userInfo.address;
       const City= userInfo.city;
       const Email= userInfo.email;

      return  <div className={classes.root}>
                {info && <ErrorMessage color="success">{info}</ErrorMessage>}
                {error && <ErrorMessage color="error">{error}</ErrorMessage>}

                <Container>
                 <Hidden smUp={true}>
                  <Link style={{display:'flex', padding: '10px', alignItems:'center'}} to="/account/delivery">
                  <ArrowBackIcon />
                  <Typography variant="subtitle1">Place order</Typography>
                 </Link>
                  <Typography>
                    Cart Summary
                  </Typography>
                  <Box className={classes.tot}>
                    <Typography>
                     Subtotal
                    </Typography>
                    <Typography style={{
                    fontSize: "20px",
                    margin: "10",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>
                      &#8358;  {Number(total).toLocaleString('en-US')}
                    </Typography>
                  </Box>
                 </Hidden>
                 <Grid container spacing={5}>
                   <Grid item xs={12} md={8}>
                     <Box className={classes.hd}>
                       <Typography variant="subtitle2">PAYMENT METHOD</Typography>
                       <Typography variant="subtitle2" color="primary">CHANGE</Typography>
                      </Box>
                      <Box className={classes.addr}>
                       <Typography variant="subtitle2">Pay with Bank Transfer or USSD</Typography>
                       <Typography variant="body2">
                        You will be directed to our secure checkout page
                       </Typography>
                      </Box>

                     <Box className={classes.hd}>
                       <Typography variant="subtitle2">CUSTOMER ADDRESS</Typography>
                       <Typography variant="subtitle2" color="primary">CHANGE</Typography>
                      </Box>
                      <Box className={classes.addr}>
                       <Typography variant="body2">{Firstname} {Lastname}</Typography>
                       <Typography variant="body2">
                        {Address} | {City} | +234 8...
                       </Typography>
                      </Box>
                      <Box className={classes.hd}>
                       <Typography variant="subtitle2">DELIVERY DETAILS</Typography>
                       <Typography variant="subtitle2" color="primary">CHANGE</Typography>
                      </Box>

                      <Box className={classes.addr}>
                       <Box className={classes.sel}>
                        <Radio checked={true} />
                        <Box className={classes.text}>
                         <Typography variant="body2">
                           Pick-up Station
                         </Typography>
                         <Typography variant="body2">{address}</Typography>
                        </Box>
                       </Box>
                       <Box className={classes.addr}>
                       <Box className={classes.hd}>
                        <Typography variant="body2">Shipment 1/1</Typography>
                        <Typography variant="body2">Fulfilled by Adijastore</Typography>
                       </Box>
                       <Box className={classes.ship}>
                        <Typography variant="subtitle2">Pick-up Station</Typography>
                        <Typography variant="body2">{address}</Typography>
                       </Box>
                       <Box className={classes.orderlist}>
                        {
                                cart.map(prod => (
                                 <OrderList prod={prod} key= {prod._id} />
                      
                                  ))
                              }
                       </Box>
                      </Box>

                      </Box>
                      
                      
                    </Grid>
                    <Hidden smUp={true}>
                     <Box className={classes.hide}>
                    
                        <Button variant="contained" color="primary" style={{width:'100%', marginBottom:'30px' }}disabled={cart.length === 0} onClick={handleOpen} >CONFIRM ORDER</Button>
                        
                     </Box>
                    </Hidden>
                    <Grid item xs={12} md={4} className={classes.check}>
                       <div>
                       <Typography variant="subtitle2" className={classes.text}>CART SUMMARY</Typography>
                        <span className={classes.text}>
                          Subtotal ({cart.length}) items</span>
                        <span className={classes.text} style={{ fontweight: 700, fontSize: 20}}> &#8358;  {Number(total).toLocaleString('en-US')}</span>
                       <div>
                     
                        <Button variant="contained" color="primary" disabled={cart.length === 0} onClick={handleOpen} >CONFIRM ORDER</Button>
                       
                        </div>
                       </div>
                    </Grid>
          </Grid>
          </Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            disableBackdropClick={true}
       
          >
          <Fade in={open}>
           <Box className={classes.paper}>
            <Box className={classes.options}>
             <Typography variant="subtitle2" style={{padding:'15px'}}>PAY WITH</Typography>
             <Divider />
             <Box className={classes.list}>
              <AccountBalanceIcon />
              <Typography variant="subtitle2" style={{marginLeft:'5px'}}>Transfer</Typography>
             </Box>
             <Divider />
             <Box className={classes.list}>
             <AddToHomeScreenIcon />
             <Typography variant="subtitle2" style={{marginLeft:'5px'}}>USSD</Typography>
             </Box>
             <Divider />
            </Box>
            <Box className={classes.number}>
             <Box className={classes.title}>
               <img
                alt="ubalogo"
                src={require("../assets/ubalogo.gif")}
                className={classes.logo}
               />
               <Box>
                <Typography variant="body2">{Email}</Typography>
                <Typography variant="body2">Products: &#8358; {Number(total).toLocaleString('en-US')}</Typography>
                <Typography variant="body2">Logistics : &#8358; {Number(transport).toLocaleString('en-US')}</Typography>
                <Divider />
                <Typography variant="body2">&#8358; {deposited}</Typography>

               </Box>
             </Box>
             <Divider />
             <Typography variant="subtitle2" style={{margin:'30px',}}>
              Transfer &#8358; {Number(deposited).toLocaleString('en-US')} to AdijaStores
             </Typography>
             <Box className={classes.details}>
              <Typography variant="body2" style={{fontSize:'12px'}}>BANK NAME</Typography>
              <Typography variant="subtitle2">
               UBA
              </Typography>
              <Typography variant="body2" style={{fontSize:'12px',marginTop:'10px'}}>ACCOUNT NUMBER</Typography>
              <Typography variant="subtitle2">
               1024641198
              </Typography>
              <Typography variant="body2" style={{fontSize:'12px',marginTop:'10px'}}>ACCOUNT NAME</Typography>
              <Typography variant="subtitle2">
               ADIJA FOOD STORAGE PARTNERSHIP AND SUPPLY
              </Typography>
              <Typography variant="body2" style={{fontSize:'12px', marginTop:'10px'}}>AMOUNT</Typography>
              <Typography variant="subtitle2">
               &#8358; {Number(deposited).toLocaleString('en-US')}
              </Typography>

             </Box>
             <Typography variant="body2" style={{ margin:'20px'}}>
              Use this account for your transaction 
             </Typography>
             <div style={{marginBottom:'10px'}}>
      <InputLabel htmlFor="upload-button-file">Please upload screenshot or image of receipt</InputLabel>
      <Input
        accept="image/png"
        className={classes.input}
        id="upload-button-file"
        type="file"
        onChange={(e) => postDetails(e.target.files[0])}
      />
     
    </div>

             <Button variant="contained" color="primary" fullWidth onClick={submitHandler} disabled={clicked}>
              <Typography variant="subtitle2" style={{fontSize:'12px'}}>
               I've sent the money
              </Typography>
             </Button>
             
           <Hidden smUp={true}>
            <Button variant="contained" color="secondary" onClick={handleDia} style={{marginTop:'10px'}}>Cancel Payment</Button>
           
           </Hidden>
           <Box style={{position:'absolute', right:-12, top:0}} onClick={handleDia}>x</Box>
            </Box>
           </Box>
          </Fade>
         </Modal>
         <Dialog
          open={diaOpen}
          onClose={closeDia}
          disableEscapekeyDown= {true}
          disableBackdropClick= {true}
          maxWidth= 'xs'
         >
          <DialogTitle>
           <Typography variant="h6">Are you sure you want to stop this order?</Typography>
          </DialogTitle>
          <DialogContent>
           <Typography variant="body1">
             Do not close this transaction if you have already made payment to our account, kindly click cancel below and then click the button I've made payment
           </Typography>
          </DialogContent>
          <DialogActions>
           <Button variant="contained" onClick={handleBoth}>Ok, stop transaction</Button>
           <Button variant="contained" onClick={closeDia}>cancel</Button>
          </DialogActions>
         </Dialog>
         </div>
   
    };

export default Summary;

import rice from "../Pages/images/rice.jpg";
import tt from "../Pages/images/tt.jpg";
const ServiceData = [
  {
    id: 1,
    img_src:
      tt,
    title: "We supply vegetables",
    description: "At most cheaper rates",
  },
  {
    id: 2,
    img_src: rice,
    title: "We supply grains",
    description: "which is already processed",
  },
  {
    id: 3,
    img_src:
      "",
    title: "",
    description: ".",
  },
  {
    id: 4,
    img_src: "",
    title: "We train",
    description: "young prospective entrepreneur.",
  }
];

export default ServiceData;

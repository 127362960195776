import React, { useState, useEffect } from "react";
import { Button, Box, Typography, makeStyles, Divider, Grid } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
   minHeight: '100vh',
   marginTop:'15px',
  },
  
  change:{
   color:'blue'
  },
  addr:{
   backgroundColor:'#fff',
   borderRadius:'5px',
   color:'#000', 
   padding:'5px',
   marginBottom:'10px',
  },
  pick:{
   display:'flex',
   alignItems:'center',
   padding:'10px',
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey'
  },
  point:{
   padding:'10px',
   borderLeft:'1px solid grey',
   borderRight:'1px solid grey'
  },
  date:{
   display:'flex',
   alignItems:'center',
   justifyContent:'space-between',
   padding:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },
  spacet:{
   padding:'10px',
   borderRadius:'5px 5px 0 0',
   height:'20px',
   boxShadow: '0px 1px 4px 0px #c8c8c8',
  },
  spaceb:{
   padding:'10px',
   borderRadius: '0 0 5px 5px',
   height: '20px',
   boxShadow: '0px 1px 4px 0px #c8c8c8',
  },
  empty: {
   padding: '10px',
   boxShadow: '0px 1px 4px 0px #c8c8c8',
   fontWeight:'400'
  }

}));

const Notifications = () => {
  const classes = useStyles();
  const [notify, setNotify]= useState([]);
  async function notif() {
    try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get("/api/notifications", config);

       setNotify(data);
       
    } catch (error) {
        console.error(error);
    }
};

useEffect(()=> {
 notif();

}, [notify]);

  return (
   <Box className={classes.root}>
   {notify === undefined || notify.length === 0 ?  (
          <>
           <Box className={classes.spacet}></Box>
           <Box className={classes.empty}>
            <Typography variant= "body1">No notifications yet</Typography>
           </Box>
           <Box className={classes.spaceb}></Box>
          </>
         ) : ( <>
   {notify.map((not, _id)=> ( <>
    <Box className={classes.addr}>
                       <Box className={classes.pick}>
                        <Typography variant="subtitle2">Icon</Typography>
                     
                        <Typography variant="body2" style={{marginLeft:'10px', color: 'green'}}>{not.title}</Typography>
                        
                       </Box>
                       <Box className={classes.point}>
                  
                        <Typography>{not.message} and a receipt has been sent to your email atanglux@gmail.com</Typography>
                       </Box>
                       <Box className={classes.date}>
                        <Typography variant="subtitle2">Jun 18, 2024 9:45</Typography>
                     
                        <Typography variant="body2" style={{marginLeft:'10px', color: 'green'}}>view ></Typography>
                        
                       </Box>
                      </Box>
               </>))}
          </>)}  
    
   </Box>
  );
};

export default Notifications;

import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { CartState } from "../Context/Context";
import { Button, Container, Grid, makeStyles, Typography, Hidden, Box } from "@material-ui/core";
import Rating  from "../components/Rating";
import CartList from "../components/CartList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Header from "../components/Header/Header";

const useStyles = makeStyles((theme) => ({
  root: {
   minHeight: '100vh',

  },
  container: {
   display: 'flex',
   flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height:'90vh',
    backgroundColor:'#fff',
    [theme.breakpoints.down('sm')]: {
      height: '45vh',
    },
  },
  check: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      backgroundColor:'#fff',
    },
  },
   text:{
    color:'#000',
   },
  info:{
    display: 'flex',
    flexDirection: 'column',
  },
  tot:{
   backgroundColor:'white',
   color:'black',
   display:'flex',
   justifyContent:'space-between',
   marginTop:'10px',
   marginBottom:'10px',
  },
  hide:{
   paddingLeft:'20px',
   paddingRight:'20px',
   width:'100%'
  },
 
}));

const CartPage = ( ) =>{
      const classes = useStyles();
      const history= useHistory();
      const { state: { cart },
        dispatch,
        amount,
        setAmount,
        items,
        setItems
      } = CartState();

       useEffect(() => {
         setAmount(cart.reduce((acc, curr)=> acc + Number(curr.price) * curr.qty,0));
       }, [cart]);

      useEffect(() => {
       const updatedItems = cart.map(product => ({
         productId: product._id,
         price: product.price,
         qty: product.qty
       }));

      setItems(updatedItems);
      
     }, [cart]);



      const selectedCart=() =>{
   history.push('/account/delivery'); 
  };
    useEffect(() => {
      window.scroll(0, 0);
      // eslint-disable-next-line
    }, [history]);

      return  <div className={classes.root}>
                <Header />
                <Container>
                 <Hidden smUp={true}>
                  <Link style={{display:'flex', padding: '10px', alignItems:'center'}} to="/account">
                  <ArrowBackIcon />
                  <Typography variant="subtitle1">Cart</Typography>
                 </Link>

                  <Typography>
                    Cart Summary
                  </Typography>
                  <Box className={classes.tot}>
                    <Typography style={{
                    fontSize: "18px",
                    margin: "10",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>
                     Subtotal
                    </Typography>
                    <Typography style={{
                    fontSize: "20px",
                    margin: "10",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>
                      &#8358; {Number(amount).toLocaleString('en-US')}
                    </Typography>
                  </Box>
                 </Hidden>
                 <Grid container spacing={5}>
                   <Grid item xs={12} md={8}>
                       <Typography>Cart ({cart.length})</Typography>
                       <div className={classes.container}>
                              {
                                cart.map(prod => (
                                 <CartList prod={prod} key= {prod._id} />
                      
                                  ))
                              }
                     
                       </div>
                    </Grid>
                    <Hidden smUp={true}>
                     <Box className={classes.hide}>
                      
                        <Button variant="contained" onClick={() => selectedCart()}   color="primary" style={{width:'100%', marginBottom:'30px' }} disabled={cart.length === 0}>Checkout (&#8358; {Number(amount).toLocaleString('en-US')})</Button>

                     </Box>
                    </Hidden>
                    <Grid item xs={12} md={4} className={classes.check}>
                       <div>
                       <h3 className={classes.text}>CART SUMMARY</h3>
                        <span className={classes.text}>
                          Subtotal ({cart.length}) items</span>
                        <span className={classes.text} style={{ fontweight: 700, fontSize: 20}}> &#8358; {Number(amount).toLocaleString('en-US')}</span>
                       <div>
              
                        <Button variant="contained" onClick={() => selectedCart()}  color="primary" disabled={cart.length === 0}>Checkout (&#8358; {Number(amount).toLocaleString('en-US')})</Button>
                      
                        </div>
                       </div>
                    </Grid>
          </Grid>
          </Container>
         </div>
   
    };

export default CartPage;

import React, { useState, useEffect } from "react";
import { Button, Box, Typography, makeStyles, Divider, Grid } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { OrderState } from "../Context/OrderContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#39445a',
    minHeight: '100vh',
  },
  mid:{
   marginTop:'0px',
   backgroundColor:'white', 
   color:'black',
   padding:'20px',
  },
  track:{
   display:'flex',
   justifyContent:'space-between',
   border:'1px solid rgba(0, 0, 0, 0.12)',
   borderRadius: '0 0 5px 5px',
   padding: '10px',
   alignItems:'center',
  },
  ord:{
   display:'flex',
   marginTop:'5px',
   justifyContent:'space-between',
   border:'1px solid rgba(0, 0, 0, 0.12)',
   borderRadius: '5px 5px 0 0',
   padding: '10px',
  },
  merged:{
   display:'flex',
   border:'1px solid rgba(0, 0, 0, 0.12)',
   
  },
  image:{
    width:'90px',
    height:'110px',
    paddingRight:'20px'
  },
  dist:{
    marginTop:'20px',
  },
  about:{
   [theme.breakpoints.down('sm')]: {
      fontSize:'10px',
    },
   fontSize: '14px',
  },
}));

const OrderDetails = () => {
  const classes = useStyles();
  const [value, setValue]= useState(0);
  const [vision, setVision]= useState([]);
  const { accessState, accessDispatch, orderId } = OrderState();
  const { info, statuses, loading, error } = accessState;
   const { _id } = useParams();

   useEffect(()=> {
     const selectedOrder = info.find((item) => item._id === orderId);
     setVision(selectedOrder);
   }, []);

   const Total= vision.qty * vision.price;
  
  const handleChange= (event, newValue) =>{
   setValue(newValue);
  };

  return (
   <Box className={classes.root}>
    <Box className={classes.mid}>
    
     <Box>
      <Typography variant="h6">
       Order Id {vision._id}
      </Typography> 
      <Typography variant="body1">
       {vision.qty} Item(s)
      </Typography>
      <Typography variant="body1">
       Placed on {vision.createdDate}
      </Typography>  
      <Typography variant="body1">
       Total &#8358; {Total}
      </Typography>
     </Box>
     <Divider />
     <Box>
      <Typography variant="subtitle1">
       ITEMS IN YOUR ORDER
      </Typography>
     </Box>
     <Box className={classes.ord}>
     {vision.status === false ? (
      <Typography>
       Status: PENDING
      </Typography>) : (
       <Typography>
       Status: PROCESSED
      </Typography>
      )
     }
      <Typography>
       See History
      </Typography>
     </Box>
     <Box className={classes.merged}>
       <img 
          className={classes.image} 
          src={vision.pic}
          alt="Product"
       />
       <Box>
        <Typography>
         {vision.name}
        </Typography>
        
        <Typography className={classes.dist}>QTY: {vision.qty}</Typography>
        <Typography>&#8358; {vision.price}</Typography>
       </Box>
     </Box>
     <Box className={classes.track}>
      <Typography>
       Delivery might take few days...
      </Typography>
     
      <Button>
       DISTANCE TRACKER
      </Button>
    
     </Box>
     <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
       <Box className={classes.ord}>
        PAYMENT INFORMATION
       </Box>
      </Grid>
      <Grid item xs={12} md={6}>
       <Box className={classes.ord}>
        DELIVERY INFORMATION
       </Box>
      </Grid>

     </Grid>
    
    </Box>
   </Box>
  );
};

export default OrderDetails;

import React from "react";
import Banner from "../../components/Banner/Banner";
import CoinsTable from "../../components/CoinsTable";
import SimpleBottomNavigation from "../../components/MainNav";
import Header from "../../components/Header/Header";

const Movies = () => {
  return (
    <>
      <Header />
 
      <SimpleBottomNavigation />
    </>
  );
};

export default Movies;

import { Box } from "@material-ui/core";
import { useState, useEffect} from "react";
import Chatbox from "../components/Chatbox";
import { ChatState } from "../Context/ChatProvider";
import axios from "axios";
import SimpleBottomNavigation from "../components/MainNav";
import SingleChat from "../components/SingleChat";


const ChatPage= ()=> {
     const [ fetchAgain, setFetchAgain]= useState(false);
     const { setSelectedChat, selectedChat, chats, setChats }= ChatState();
    
     const client = JSON.parse(localStorage.getItem("userInfo"));

     const startChat= async () =>{
     try{
          
           const config= {
               headers: {
                   "Content-type" : "application/json",
                    Authorization: `Bearer ${client.token}`,
               },
           };
          const { data } = await axios.get(`/api/chat/client`, config);
         
          setSelectedChat(data);
     }  catch(error){
           alert("error");
     }
   };

   useEffect(()=> {
         startChat();
       }, []);

   return(
     <div style={{ width: "100%" }}>
         <Box d="flex" justifyContent= "space-between" w="100%" h="91.5vh" p="10px">
          {client && (
               <SingleChat fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
          )}
        </Box>
        <SimpleBottomNavigation />
     </div>
   );
};
export default ChatPage;
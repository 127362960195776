import axios from "axios";
import { Container } from "@material-ui/core"; 
import "./Trending.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProductDetail from "../../components/ProductDetail";
import SingleContent from "../../components/SingleContent/SingleContent";
import {CartState} from "../../Context/Context";
import SimpleBottomNavigation from "../../components/MainNav";
import Header from "../../components/Header/Header";

const Trending = () => {
  const history= useHistory();
  const { 
      state: { categories },
      productState : {byStock, byFastDelivery, sort, byRating, searchQuery}, 
     
  }= CartState();

  /* const { loginState } = AuthState();
   const { user } = loginState; 
  
   console.log(user); */
  
   const vegetableProducts = categories['grains'] || [];
   const transformProducts= () =>{
    let sortedProducts= vegetableProducts;
    if(sort){
       sortedProducts= sortedProducts.sort((a,b)=>
         sort=== "lowToHigh" ? a.price-b.price:b.price-a.price
       );
    }
   
    if(byStock){
       sortedProducts= sortedProducts.filter((prod) => prod.inStock);
    }
    
    if(byFastDelivery){
       sortedProducts= sortedProducts.filter((prod) => prod.fastDelivery);
    }
 
    if(byRating){
       sortedProducts= sortedProducts.filter((prod) => prod.ratings >= byRating);
    }

    if(searchQuery){
       sortedProducts= sortedProducts.filter((prod) => prod.name.toLowerCase().includes(searchQuery));
    }

    return sortedProducts;
   
  }

  useEffect(() => {
      window.scroll(0, 0);
      // eslint-disable-next-line
    }, [history]);

  return (
    <div>
      <Header />
     
      <div className="main">
      <h2
                  style={{
                    fontSize: "36px",
                    margin: "7",
                    textAlign: "center",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
                >
              Grains and Legumes
            </h2>      
      <Container>
      <div className="trending">
        {
        transformProducts().map((prod)=> {
         return <SingleContent 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
      </div>
      </Container>
      </div>
    </div>
    
  );
};

export default Trending;

import React,{ useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { Link, useHistory } from 'react-router-dom';
import './Login.css';
import Spinner from '../components/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthState } from "../Context/AuthContext";
const useStyles = makeStyles((theme) => ({
  
}));

export default function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [val, setVal]= useState("");
  const [loader, setLoader] = useState('flex');

  const { login, loginState, loginDispatch } = AuthState();
  const { user, loading, error } = loginState;

  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };


    const handleValidation = () => {
     if (email === '' || password === '') {
      toast.error('email and Password is required', toastOptions);
      return false;
     }
    return true;
   };
 
    const submitHandler= async (e)=>{
     e.preventDefault();
     if (handleValidation()) {
      loginDispatch(login(email, password));
     }
    };


 useEffect(() => {
    if (user) {
     const redirectPath = localStorage.getItem('redirectPath') || '/account';
     history.push(redirectPath);
     localStorage.removeItem('redirectPath'); 
    }
  }, [history, user]);

  return (
    <>
      <div className="login_form_section">
        <div className="form_container_for_login">
          <form  className="login_u_form" onSubmit={submitHandler}>
            <div className="brand">
              <img src={require("../assets/storelogo.jpg")} alt="logo" />
              <h1>ADIJA FOOD</h1>
            </div>
            <input
              type="text"
              placeholder="Enter your Email"
              name="email"
              onChange= {(e)=> setEmail(e.target.value)}
            />
            <input
              type="Password"
              placeholder="Password"
              name="password"
              onChange= {(e)=> setPassword(e.target.value)}
            />
            <button className="login_form_button" type="submit">
              Login In
              {loading && <Spinner id="login_loder" style={loader} />}
            </button>
            <span className="lower_title_login">
              Don't have an account ?
              <Link to={'/account/register'}>Register</Link>
            </span>
          </form>
        </div>
       {error && toast.error(error, toastOptions) }
       <ToastContainer />
      </div>
    </>

  );
}
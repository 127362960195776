export const userLoginReducer = (state, action)  =>{
  switch (action.type) {
    case "USER_LOGIN_REQUEST":
      return { ...state, loading: true };
    case "USER_LOGIN_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "USER_LOGIN_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "USER_LOGOUT":
      return { ...state, user: null };
    default:
      return state;
  }
}

// User register reducer
export const userRegisterReducer = (state, action) => {
  switch (action.type) {
    case "USER_REGISTER_REQUEST":
      return { ...state, loading: true };
    case "USER_REGISTER_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "USER_REGISTER_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

// User update reducer
export const userUpdateReducer= (state, action) => {
  switch (action.type) {
    case "USER_UPDATE_REQUEST":
      return { ...state, loading: true };
    case "USER_UPDATE_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "USER_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
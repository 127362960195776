import { Avatar, Tooltip, Box, Container } from "@material-ui/core";
import {
   isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../config/ChatLogics";
import { ChatState } from "../Context/ChatProvider";

const ScrollableChat= ({ messages }) =>{
    const { client } = ChatState();
    return(
   
      <Box>
       <Container>
        {messages &&
         messages.map((m, i)=> (
             <div style={{ display: "flex"}} key={m._id}>
               {(isSameSender(messages, m, i, client._id) || 
                  isLastMessage(messages, i, client._id)) && (
                     <Tooltip label={m.sender.name} placement="bottom-start" hasArrow>
                      <Avatar
                        mt="7px"
                        mr={1}
                        size="sm"
                        cursor="pointer"
                        name={m.sender.name}
                     />
                     </Tooltip>
                  )
              }
              <span
                 style={{
                     backgroundColor: `${
                        m.sender._id === client._id ? "#BEE3F8" : "#B9F5D0"
                     }`,
                     marginLeft: isSameSenderMargin(messages, m, i, client._id),
                     marginTop: isSameUser(messages, m, i, client._id) ? 3 : 10,
                     borderRadius:"20px",
                     padding: "5px 15px",
                     maxWidth: "75%",
                  }}
               >
                  {m.content}
                </span>
              </div>
         ))}
        </Container>
       </Box>
     );
};
export default ScrollableChat;
import { createContext, useContext, useReducer, useEffect, useState } from "react";
import { cartReducer } from "./Reducers";
import { productReducer } from "./Reducers";
import { investReducer } from "./Reducers";
import { storeReducer } from "./Reducers";
import axios from "axios";
import Cookies from "universal-cookie";

const Cart= createContext();

const Context= ({children}) =>{
         const cookies = new Cookies();

         const [productId, setProductId] = useState();
         const [value, setValue] = useState(0);
         const [selectedProduct, setSelectedProduct] = useState([]);
         const [amount, setAmount]= useState();
         const [address, setAddress]= useState("");
         const [txnref, setTxnref]= useState("");
         const [items, setItems]= useState([]);
         const [direct, setDirect]= useState(true);
         const [paystack, setPaystack]= useState(false);
         const [transport, setTransport]= useState();

         const[investState, investDispatch] = useReducer(investReducer, {
invests:[],
store:[]
});
  
      
      useEffect(()=> {
     fetchInvest();
   }, []);


      const fetchInvest = async()=> {
     const response = await axios.get('/api/investproducts');
      const invest= response.data;
      investDispatch({
        type: "FETCH_INVEST_DATA",
        payload: invest
      });
     
   };

   
    const fetchCart = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    try {
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const response = await axios.get(`/api/cart`, config);
        const data = response.data;

        const cartItemsArray = [];

        // Loop through each element in the 'data' array
        for (let i = 0; i < data.length; i++) {
            const cartItem = data[i];
            // Check if 'products' is defined and is an array
            if (cartItem.products) {
                // Loop through each product in the 'products' array
                for (let j = 0; j < cartItem.products.length; j++) {
                    const product = cartItem.products[j];
                    const name = product.productId.name;
                    const price = product.productId.price;
                    const qty = product.qty;
                    cartItemsArray.push({name, price, qty});
                }
            } else {
                console.error("Products data is missing or not an array.");
            }
        }

        console.log(cartItemsArray);
        dispatch({
         type: "ADD_TO_CART",
         payload: cartItemsArray
        });
     
    } catch (error) {
        console.error("Error fetching cart:", error);
    }
};
    useEffect(()=> {
     fetchCart();
    },[]);
      
      const[state, dispatch] =useReducer( cartReducer, {
         products:[],
         cart:[],
         categories:{},
      });
      
   
   useEffect(() => {
    if(state.cart.length === 0) return;
    else{
     cookies.set('cartState', state.cart, {path: '/'});
    }
   },[state.cart, cookies]);

   useEffect(()=>{ 
    const allcooks= cookies.get('cartState');
    if(state.cart.length === 0 && allcooks){
    dispatch({
         type: "ADD_TO_CART",
         payload: allcooks
        });

    }else return;
   },[]);

      useEffect(()=> {
     fetchProducts();
   }, []);
     
     
     const fetchProducts = async()=> {
     const response = await axios.get('/api/products');
      const products= response.data;
      dispatch({
        type: "FETCH_DATA_SUCCESS",
        payload: products
      });
       
      dispatch({ type: "GROUP_BY_CATEGORY", payload: products});
   };
    
      
      const [productState, productDispatch] = useReducer( productReducer, {
         byStock: false,
         byFastDelivery: false,
         byRating: 0,
         searchQuery: "",
      });

      const [storeState, storeDispatch] = useReducer( storeReducer, {
         onStock: false,
         onFastDelivery: false,
         onRating: 0,
         seekQuery: "",
      });

  
    
      return <Cart.Provider value={{state, dispatch, productState, productDispatch, investState, investDispatch, storeState, storeDispatch, productId, setProductId, selectedProduct, setSelectedProduct, value, setValue, amount, setAmount, address, setAddress, txnref, setTxnref, items, setItems, direct, setDirect, paystack, setPaystack, transport, setTransport}}>{children}</Cart.Provider>;
     
};

export default Context;

export const CartState=()=>{
    return useContext(Cart);
};
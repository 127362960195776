import React, { useState, useEffect } from "react";
import { Button, Box, Tab, Tabs, Typography, makeStyles, Divider } from "@material-ui/core";
import SimpleBottomNavigation from "../../components/MainNav";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { OrderState } from "../../Context/OrderContext";
import Header from "../../components/Header/Header";

const useStyles = makeStyles((theme) => ({
  root: {
  
   minHeight: '100vh',

  },
  mid:{
   marginTop:'20px',
   backgroundColor:'white', 
   color:'black',
   padding:'20px',
  },
  ord:{
   display:'flex',
   marginTop:'5px',
   marginBottom:'30px',
   justifyContent:'space-between'
  },
  merged:{
   display:'flex',
  },
  image:{
    width:'60px',
    height:'60px',
    paddingRight:'20px'
  },
  tabs:{backgroundColor:'#fff'},
  about:{
   [theme.breakpoints.down('sm')]: {
      fontSize:'10px',
    },
   fontSize: '14px',
  },
}));

const Series = () => {
  const classes = useStyles();
  const history= useHistory();
  const [value, setValue]= useState(0);
  const { accessState, accessDispatch, orderId, setOrderId } = OrderState();
  const { info, statuses, loading, error } = accessState;

  const orders = statuses['true'] || [];

  const pends = statuses['false'] || [];

  const handleChange= (event, newValue) =>{
   setValue(newValue);
  };
  
 const singlePend=(_id) =>{
   history.push(`/account/details/${_id}`); 
   setOrderId(_id);
  };

const singleOrder=(_id) =>{
   history.push(`/account/details/${_id}`);
   setOrderId(_id);
  };



  return (
    <Box className={classes.root}>
     <Header />
     <Box>
      <Tabs
       value={value}
       onChange={handleChange}
       indicatorColor="primary"
       textColor="primary"
       centered
       variant="fullWidth"
       className={classes.tabs}
      >
       <Tab label="PROCESSED" />
       <Tab label="PENDING" />
      </Tabs>

      {value === 0 && (
        <Box className={classes.mid}>
         <Typography variant="h6">
          Verified Orders ({orders.length})
         </Typography>
         <Divider />
         {orders ? (<>
          { orders.map(order =>(
        <>
         <Box className={classes.ord}>
          <Box className={classes.merged}>
           <img 
             className={classes.image} 
             src={order.pic}
             alt="Product"
            />
            <Box>
             <Typography variant="subtitle2">
              {order.name}
             </Typography>
             <Typography variant="body2">
              Placed on {order.createdDate}
             </Typography>

             <Typography variant="subtitle2">
               PROCESSED
             </Typography>
             
            </Box>
          </Box>
          <Box onClick={() => singleOrder(order._id)}>
          <Typography variant="subtitle2">
           SEE DETAILS
          </Typography>
          </Box>
         </Box>
         <Divider />
          </>
          ))}
        </>)
          :
         (<Box className={classes.tabs}>
           <Typography>Orders are empty</Typography>
          </Box>)}
        </Box>
      )}

      {value === 1 && (
        <Box className={classes.mid}>
         <Typography variant="h6">
          Pending Orders ({pends.length})
         </Typography>
         <Divider />
         {pends ? (<>
          { pends.map(pend =>(
        <>
         <Box className={classes.ord}>
          <Box className={classes.merged}>
           <img 
             className={classes.image} 
             src={pend.pic}
             alt="Product"
            />
            <Box>
             <Typography variant="subtitle2">
              {pend.name}
             </Typography>
             <Typography variant="body2">
              Placed on {pend.createdDate}
             </Typography>
             
             <Typography variant="subtitle2">
               PENDING...
             </Typography>
            </Box>
          </Box>
          <Box onClick={() => singlePend(pend._id)}>
          <Typography variant="subtitle2">
           SEE DETAILS
          </Typography>
          </Box>
         </Box>
         <Divider />
          </>
          ))}
        </>)
          :
         (<Box className={classes.tabs}>
           <Typography>Orders are empty</Typography>
          </Box>)}
        </Box>

      )}
     </Box>
     <SimpleBottomNavigation />
    </Box>
  );
};

export default Series;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const ChatContext = createContext();
const ChatProvider= ({ children }) =>{
      const [selectedChat, setSelectedChat] = useState();
      const [client, setClient] = useState();
      const [notification, setNotification] = useState([]);
      const [chats, setChats] = useState();
     
      const history = useHistory(); 
       useEffect(()=> {
           const userInfo= JSON.parse(localStorage.getItem("userInfo"));
           setClient(userInfo);
      
        }, [history]);
    return(
       <ChatContext.Provider
            value={{
                 selectedChat,
                 setSelectedChat,
                 client,
                 setClient,
                 notification,
                 setNotification,
                 chats,
                 setChats,
            }}
         >
           {children}
      </ChatContext.Provider>
     );
};
export const ChatState= () =>{
    return useContext(ChatContext);
};
export default ChatProvider;
export const isSameSenderMargin= (messages, m, i, adminId) =>{
    if(
        i < messages.length - 1  &&
       messages[i + 1].sender._id !==adminId
    )
       return 33;
     else if(
        (i < messages.length - 1 && 
          messages[i + 1].sender._id === m.sender._id &&
          messages[i].sender._id !== adminId) ||
      (i === messages.length - 1 && messages[i].sender._id !== adminId)
     )
       return 0;
      else return "auto";
   };

export const isSameSender= (messages, m, i, adminId)=> {
    return (
        i  < messages.length - 1 && 
        (messages[i + 1].sender._id !== m.sender._id || 
          messages[i + 1].sender._id === undefined) &&
         messages[i].sender._id !==adminId
       );
      };
    export const isLastMessage= (messages, i, adminId)=>{
        return(
           i === messages.length - 1 && 
          messages[messages.length - 1].sender._id !== adminId &&
           messages[messages.length - 1].sender._id
        );
    };
export const isSameUser= (messages, m, i)=>{
   return i>0 && messages[i - 1].sender._id === m.sender._id;
};
export const getSender = (loggedUser, admins)=> {
   return admins[0]?._id === loggedUser?._id ? admins[1].name :admins[0].name;
};
export const getSenderFull = (loggedUser, admins)=> {
   return admins[0]._id === loggedUser._id ? admins[1] : admins[0];
};
import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CartState } from "../Context/Context";
import { Button, Container, Grid, makeStyles, Typography, Hidden, Box, Radio, Modal, Backdrop, Divider, Fade, FormControl, MenuItem, InputLabel, FormHelperText, TextField} from "@material-ui/core";
import Select, {SelectChangeEvent} from "@material-ui/core/Select";
import Rating  from "../components/Rating";
import OrderList from "../components/OrderList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
   minHeight: '100vh',
  },
  disabledButton:{
   backgroundColor: theme.palette.primary || 'red',
   color:'#fff'
  },
  container: {
   display: 'flex',
   flexDirection: 'column',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "35%",
    height: "60%",
    backgroundColor: "#fff",
    border: "1px solid #282c34",
    borderRadius: 5,
    color: "black",
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      height:'100%',
    },
  },
  check: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      backgroundColor:'#fff',
    },
  },
   text:{
    color:'#000',
   },
  info:{
    display: 'flex',
    flexDirection: 'column',
  },
  tot:{
   backgroundColor:'white',
   color:'black',
   display:'flex',
   justifyContent:'space-between',
   marginTop:'10px',
  },
  hide:{
   paddingLeft:'20px',
   paddingRight:'20px',
   width:'100%'
  },
  hd:{
   display:'flex',
   justifyContent: 'space-between',
   marginTop:'10px',
  },
  change:{
   color:'blue'
  },
  addr:{
   backgroundColor:'#fff',
   borderRadius:'5px',
   color:'#000', 
   padding:'5px',
   marginBottom:'10px',
   marginTop:'10px',
  },
  sel:{
   display:'flex',
   alignItems:'center',
  },
  pick:{
   display:'flex',
   justifyContent:'space-between',
   padding:'10px',
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey'
  },
  point:{
   padding:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },
  ship:{
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey',
   padding:'10px'
  },
  orderlist:{
   
   paddingLeft:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },

}));

const Delivery = ( ) =>{
      const classes = useStyles();
      const history = useHistory();
      const location = useLocation();
      
      useEffect(() => {
      window.scroll(0, 0);
      // eslint-disable-next-line
    }, [history]);

      const { state: { cart },
        dispatch,
        amount,
        address,
        setAddress,
        setTransport,
        transport,
      } = CartState();

       const [insert, setInsert]= useState(false);
       const [open, setOpen] = useState(false);
       const [score, setScore] = useState('');
       const [opt, setOpt]= useState([]);
       const [selected, setSelected]= useState('');
       const [chosen, setChosen]= useState(false);
       const[ total, setTotal] = useState();
      
       useEffect(() => {
         setTotal(cart.reduce((acc, curr)=> acc + Number(curr.price) * curr.qty,0));
       
       }, [cart]);
       
       useEffect(() => {
        setTransport(cart.reduce((acc, curr)=> acc + (Number(curr[score]) || 0)  * curr.qty,0));
       
        },[score]);

       const userInfo = JSON.parse(localStorage.getItem("userInfo"));

       useEffect(() => {
        if (!userInfo) {
          const currentPath = location.pathname;
          localStorage.setItem('redirectPath', currentPath);
          history.push('/account/login');
        }
       }, [userInfo, history, location]);

    
       const handleOpen = () => {
        setOpen(true);
       };

       const handleClose = () => {
        setOpen(false);
       };

       const scoreData = ['Abia', 'Anambra', 'AkwaIbom', 'Bayelsa','Benue','Borno','Enugu','Kwara', 'Lagos', 'Nasarawa', 'Ondo','Ogun', 'Osun', 'Oyo', 'Rivers', 'Abuja'];
  const options = {
    'Abia': ['Umuahia'],
    'Anambra': ['Onitsha- Opiweka road'],
    'AkwaIbom': ['A K T C'],
    'Bayelsa': ['Yenagoa- Ekeki park'],
    'Benue': ['Markurdi- Otukpo'],
    'Borno': ['Maiduguri- Borno express'],
    'Enugu': ['Obolafo- 9th mile'],
    'Kwara': ['Ilorin'],
    'Lagos': ['Ijora- 7 up',],
    'Nasarawa': ['Lafia'],
    'Ondo': ['Akure- Shasha filling station',],
    'Ogun': ['Berger', 'Ogere',],
    'Osun': ['Ife- Ilesha by breweries',],
    'Oyo': ['Ibadan- Iwo road NNPC mega station',],
    'Rivers': ['Port Harcourt- Oil mill'],
    'Abuja': ['Nyanya'],
  };
  const handleChange = (event) => {
    
    setScore(event.target.value);
    setOpt(options[event.target.value] || []);
  };

  const handleSelect = (event) => {
    setSelected(event.target.value);
  
  };
  
   const handleRadioChange = () => {
    setChosen(true);
  };
  const handleAddress= () => {
    setAddress(selected);
    handleClose();
  };

  useEffect(()=> {
   localStorage.setItem("Delivery", JSON.stringify(selected));
  },[selected]);

  const decided= JSON.parse(localStorage.getItem("Delivery"));

  const Summary=() =>{
   history.push('/account/summary'); 
  };

      return  <div className={classes.root}>
                <Container>
                 <Hidden smUp={true}>
                  <Link style={{display:'flex', padding: '10px', alignItems:'center'}} to="/account/cart">
                  <ArrowBackIcon />
                  <Typography variant="subtitle1">Select delivery method</Typography>
                 </Link>
                  <Typography variant="subtitle2">
                    Cart Summary
                  </Typography>
                  <Box className={classes.tot}>
                    <Typography>
                     Subtotal
                    </Typography>
                    <Typography style={{
                    fontSize: "20px",
                    margin: "10",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>
                     &#8358; {Number(total).toLocaleString('en-US')}
                    </Typography>
                  </Box>
                 </Hidden>

                 <Grid container spacing={5}>
                   <Grid item xs={12} md={8}>
                     <Box className={classes.hd}>
                       <Typography variant="subtitle2">CUSTOMER ADDRESS</Typography>
                       <Typography variant="subtitle2" color="primary">CHANGE</Typography>
                      </Box>
                      <Box className={classes.addr}>
                    
                          <Typography variant="body2">-----</Typography>
                       <Typography variant="body2">
                        ---- | ---- | ---
                       </Typography>
                       
                      </Box>
                      <Typography variant="subtitle2">DELIVERY METHOD</Typography>
                      <Box className={classes.addr}>
                       <Box className={classes.sel}>
                        <Radio checked={true} />
                        <Box className={classes.text}>
                         <Typography variant="body2">
                           Pick-up Station
                         </Typography>
                         <Typography variant="body2">Delivery might take few days</Typography>
                        </Box>
                       </Box>
                       <Box className={classes.pick}>
                        <Typography variant="subtitle2">Pickup Station</Typography>
                        {insert === false ? (
                          <Box variant="text" onClick={handleOpen} ><Typography variant="body2" color="primary">Select pickup station ></Typography></Box>
                         ) :(
                        <Typography variant="body2" color="primary">Change ></Typography>
                        )}
                       </Box>
                       <Box className={classes.point}>
                       {!selected ? (
                        <>
                        <Typography variant="body2"> No Pickup Station Selected</Typography>
                        <Typography>To use this option, you will need to add a pickup station near your
location</Typography>
                        </>):
                          
                        (<>
            <Typography variant="body2">Adijstores Pickup Station</Typography>
                        <Typography variant="body2">
                         {selected}
                        </Typography> 
                        </>
                       )}
                       </Box>
                      </Box>
                      <Typography variant="subtitle2">SHIPMENT</Typography>
                      <Box className={classes.addr}>
                       <Box className={classes.hd}>
                        <Typography variant="subtitle2">Shipment 1/1</Typography>
                        <Typography variant="body2">Fulfilled by Adijastore</Typography>
                       </Box>
                       <Box className={classes.ship}>
                        <Typography variant="body2">Pick-up Station</Typography>
                        <Typography variant="body2">{address}</Typography>
                       </Box>
                       <Box className={classes.orderlist}>
                        {
                                cart.map(prod => (
                                 <OrderList prod={prod} key= {prod._id} />
                      
                                  ))
                              }
                       </Box>
                      </Box>

                    </Grid>
                    <Hidden smUp={true}>
                     <Box className={classes.hide}>
                     
                        <Button variant="contained" onClick={() => Summary()} color="primary" style={{width:'100%', marginBottom:'30px' }} disabled={!decided}>Confirm delivery</Button>

                     </Box>
                    </Hidden>
                    <Grid item xs={12} md={4} className={classes.check}>
                       <div>
                       <h3 className={classes.text}>CART SUMMARY</h3>
                        <span className={classes.text}>
                          Subtotal ({cart.length}) items</span>
                        <span className={classes.text} style={{ fontweight: 700, fontSize: 20}}> &#8358;  {Number(total).toLocaleString('en-US')}</span>
                       <div>
                       
                        <Button variant="contained" onClick={() => Summary()}  color="primary" disabled={!decided}>Confirm delivery</Button>
                        
                        </div>
                       </div>
                    </Grid>
          </Grid>
          </Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
       
          >
          <Fade in={open}>
           <Box className={classes.paper}>
            <Box style={{display:'flex', padding: '10px', backgroundColor:'#fff', alignItems:'center',}} onClick={handleClose}>
              <ArrowBackIcon />
              <Typography variant="h6">Pickup Station</Typography>
            </Box>
            <Box style={{padding:'10px'}}>
            <Typography variant="subtitle2">SELECT A LOCATION</Typography>
            <div>
             <FormControl fullWidth={true}>
              {score ? <InputLabel id= "custom-select-label">Region</InputLabel> : ''}
               <Select 
                 variant="outlined"
                 labelId="custom-select-label"
                 id="custom-select"
                 value={score}
                 label="Region"
                 onChange={handleChange}
                 displayEmpty
                 renderValue={(value)=> value ? 
                  value: <Typography variant="subtitle2">Select region </Typography>
                 }
                 
                >
                  {scoreData.map((scoreValue)=>{
                   return <MenuItem value={scoreValue}>{scoreValue}</MenuItem>
                  })}
               </Select>
             </FormControl>
            </div>
            <div style={{marginTop:20}}>
             <FormControl fullWidth={true}>
              {selected ? <InputLabel id= "custom-select-label">City</InputLabel> : ''}
               <Select 
                 variant="outlined"
                 labelId="custom-select-label"
                 id="custom-select"
                 value={selected}
                 label="City"
                 onChange={handleSelect}
                 displayEmpty
                 renderValue={(value)=> value ? 
                  value: <Typography variant="subtitle2">Select city </Typography>
                 }
                 
                >
                  {opt.map((sel)=>{
                   return <MenuItem value={sel}>{sel}</MenuItem>
                  })}
               </Select>
             </FormControl>
            </div>
            </Box>
            <Box style={{padding:'10px'}}>
            <Typography variant="subtitle2">PICKUP STATIONS NEAR YOU</Typography>
           
            <Box className={classes.sel}>
              <Radio checked={chosen} onChange={handleRadioChange} />
              <Box className={classes.text}>
              <Typography variant="body2">
                Adijastore Pickup Station
              </Typography>
              </Box>
            </Box>
              <Typography variant="body2">{selected}</Typography>

            </Box>
            <Button variant="contained" fullWidth={true} color="primary" style={{marginTop:'10px'}} disabled={!chosen} onClick={handleAddress}>
            SELECT PICKUP STATION
            </Button>
           </Box>
          </Fade>
         </Modal>

         </div>
   
    };

export default Delivery;

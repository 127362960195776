import React,{ useState, useEffect } from 'react';
import { useParams, } from "react-router-dom";
import axios from "axios";
import { Grid, makeStyles, Paper, Button, Typography } from '@material-ui/core';
import Rating from "../components/Rating";
import SingleProduct from "../components/SingleProduct";
import {CartState} from "../Context/Context";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  root: {
   flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height:'70vh',
    backgroundColor:'#fff',
  },
  image: {
   width:'100%',
   height:'40%',
  },

  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const ProductDetail = () => {
  const classes = useStyles();

  const { _id } = useParams();

  
  const [single, setSingle] = useState([]);
  const [video, setVideo] = useState();
  const { state: { cart },
     dispatch,
     productId,
       } = CartState();
  const singleItem= async ()=>{
           try{
               const config= {
                  headers: {
                     "Content-type": "application/json",
                  },
               };
                const { data }= await axios.get(
                   `/api/products/${_id}`,
                   config
                 );
            
                setSingle(data);
                console.log(single);
           } catch{
             
           }
       };
    useEffect(()=> {
         singleItem();
       }, []);

  return (
   <div className={classes.root} justifyContent='center'>
    {
     <SingleProduct 
       single={single}
       key={single._id}
     />
    }
  </div>
  );
};

export default ProductDetail;
import {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Badge, Button, Typography, Box, } from "@material-ui/core";
import "./SingleContent.css";
import {CartState} from "../../Context/Context";

const SingleContent = ({ prod }) => {
 
  const { state: { cart },
     dispatch,
    
   } = CartState();
    
  const history= useHistory();

  const cartItem = cart.find(item => item._id === prod._id);
  const singleProd=() =>{

   history.push(`/account/product/${prod._id}`); 
  }
 
  console.log(cart);
  return (
 <>
   <div className="root">
    <div className="single_an">
    <div
        onClick={() => singleProd()}
        className="media"
        style={{ cursor: "pointer" }}
        color="inherit"
      >
    
      <Badge
        badgeContent={prod.ratings}
        color={prod.ratings > 2 ? "primary" : "secondary"}
      />
      <img
        className="poster"
        src={prod.pic}
        alt={prod.name}
      />
      <Typography variant="body2" className="title" style={{fontWeight:'400'}}>{prod.name}</Typography>
      <Typography variant="subtitle2" style={{
                    fontSize: "16px",
                    margin: "2px",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
> &#8358; {Number(prod.price).toLocaleString('en-US')}</Typography>
      </div>
   
      <div className="btn">
      {
                      cart.some(p=> p._id===prod._id)? (
                       <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        
           <Button color="secondary" variant="contained" 
                  
onClick={()=>{
                      if(cartItem.qty > 1) {    
                      dispatch({
                                 type: 'DECREASE_QUANTITY',
                                 payload:prod,
                          });
                      }else{
                         dispatch({
                                 type: 'REMOVE_FROM_CART',
                                 payload:prod,
                        });
                     }

                       }}
       >-</Button>

           <span>{cartItem.qty}</span>
           <Button color="secondary" variant="contained" onClick={()=>{
                             dispatch({
                                 type: 'INCREASE_QUANTITY',
                                 payload:prod,
                          });
                       }}
       >+</Button>
            
          </Box>
                      ): (<Button color="primary" fullWidth variant="contained" onClick={()=>{
                             dispatch({
                                 type: 'ADD_TO_CART',
                                 payload:prod,
                          });
                       }}
                          
                      disabled= {!prod.inStock}>
                    {!prod.inStock ? "Out of stock" : "Add to cart"}</Button>
)
                       
                  }
     </div>
    </div>
   </div>
</>
  );
};

export default SingleContent;

import React from "react";
import { Alert } from '@material-ui/lab';
const ErrorMessage = ({variant= "standard", children, color}) =>{
     return(
         <div>
             <Alert color={color}>
                     <strong>{children}</strong>
            </Alert> 
        </div>
     )
}
export default ErrorMessage;
import React from 'react';
import { Link } from 'react-router-dom';
import about_long_des_data from '../Pages/PagesData/AboutData';
import './LowerFooter.css';
import footer_01 from '../Pages/images/footer_01.jpg';
import footer_02 from '../Pages/images/footer_01.jpg';

function LowerFooter() {
  const contact_recent_data = [
    {
      index: 1,
      rp_img: footer_01,
      rp_text:
        'Sharing our knowledge in food processing and storage and also delivery is a delight to us as we hope to inspire a lot of great minds.',
      rp_btn: 'read more',
    },
    {
      index: 2,
      rp_img: footer_02,
      rp_text:
        'Having food products delivered to you in affordable and good conditions is our ultimate goal.',
      rp_btn: 'read more',
    },
  ];

  const contact_data = [
    {
      index: 1,
      title: 'Email:',
      cu_data: ' adijafoodstorage@gmail.com',
    },
    {
      index: 2,
      title: 'Phone:',
      cu_data: ' 09028623015',
    },
  ];

  const contact_link = [
    {
      index: 1,
      img: <i class="fa-brands fa-facebook-f"></i>,
      on_link: 'https://www.facebook.com/',
    },
    {
      index: 2,
      img: <i class="fa-brands fa-instagram"></i>,
      on_link: 'https://www.instagram.com/',
    },
    {
      index: 3,
      img: <i class="fa-brands fa-twitter"></i>,
      on_link: 'https://www.twitter.com/',
    },
  ];
  return (
    <>
      <div className="lower_outer_footer_container">
        <div className="about_us">
          <div className="wrapper_container_ab">
            <h2>About Us</h2>
            <p data-aos="fade-right">{about_long_des_data.text}</p>
          </div>
        </div>
        <div className="recent_posts">
          <h2>Recent Post</h2>
          {contact_recent_data.map((data, index) => {
            return (
              <div
                className="rp_data"
                key={index}
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <img  alt="" />
                <div className="rp_compile_container">
                  <p>{data.rp_text}</p>
                  <a href="#">{data.rp_btn}</a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="contact_us">
          <div className="wrapper_container_cu">
            <h2>Contact Us</h2>
            <div
              className="contact_address"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <address>
                 Shop- 5, Tomatoe market, Bauchi ring road, Jos,
                Plateau State, Nigeria.
              </address>
            </div>
            <div className="contact_data">
              {contact_data.map((data, index) => {
                return (
                  <p
                    className="cs_data"
                    key={index}
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <span style={{ color: '#0396fe' }}>{data.title} </span>
                    {data.cu_data}
                  </p>
                );
              })}
            </div>
            <div className="contact_links">
              {contact_link.map((data, index) => {
                return (
                  <a
                    className="contact_data_links"
                    key={index}
                    href={data.on_link}
                  >
                    {data.img}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LowerFooter;

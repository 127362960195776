import React, { useState } from 'react';
import { usePaystackPayment } from "react-paystack";

const config= {
 reference: (new Date()).getTime().toString(),
 email: "user@gmail.com",
 amount: 20000,
 publicKey:'pk_test_89b11fcea08ff60cb5c37171930ab87e7ea166d8',
};

const onSuccess = (reference)=>{
 alert(reference);
};

const onClose= ()=>{
 alert('closed');
};

const PayStackHookExample = () =>{
  const initializePayment =usePaystackPayment(config);
  return (
   <div>
    <button onClick={()=>{
      initializePayment(onSuccess, onClose)
     }}>Paystack Hooks Implementation</button>
   </div>
  );
};

const CheckOutPage = () => {  
       
  return <PayStackHookExample />
    
};

export default CheckOutPage;
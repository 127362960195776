import axios from "axios";
import { Grid, Container, makeStyles, Typography } from "@material-ui/core"; 
import "./Products.css";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ProductDetail from "../../components/ProductDetail";
import SingleCategory from "../../components/SingleCategory";
import {CartState} from "../../Context/Context";
import SimpleBottomNavigation from "../../components/MainNav";
import Header from "../../components/Header/Header";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
   flexGrow: 1,
  },
  header:{
   
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height:'70vh',
    backgroundColor:'#fff',
  },
  image: {
   width:'100%',
   height:'40%',
  },

  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const Products = () => {
  const classes = useStyles();
  const history= useHistory();
  const { 
      state: { categories },
      productState : {byStock, byFastDelivery, sort, byRating, searchQuery}, 
      productId, setProductId, selectedProduct, setSelectedProduct,
  }= CartState();

  /* const { loginState } = AuthState();
   const { user } = loginState; 
  
   console.log(user); */
  
   const vegetableProducts = categories['vegetables'] || [];
   const sixArray = vegetableProducts.slice(0,6);
   const transformProducts= () =>{
    let sortedProducts= sixArray;
    if(sort){
       sortedProducts= sortedProducts.sort((a,b)=>
         sort=== "lowToHigh" ? a.price-b.price:b.price-a.price
       );
    }
   
    if(byStock){
       sortedProducts= sortedProducts.filter((prod) => prod.inStock);
    }
    
    if(byFastDelivery){
       sortedProducts= sortedProducts.filter((prod) => prod.fastDelivery);
    }
 
    if(byRating){
       sortedProducts= sortedProducts.filter((prod) => prod.ratings >= byRating);
    }

    if(searchQuery){
       sortedProducts= sortedProducts.filter((prod) => prod.name.toLowerCase().includes(searchQuery));
    }

    return sortedProducts;
   
  }
  const grainProducts = categories['grains'] || [];
  const sixGrainArray = grainProducts.slice(0,6);
  
  const fishProducts = categories['fishes'] || [];
  const sixFishArray = fishProducts.slice(0,6);
 
  const oilProducts = categories['oils'] || [];
  const sixOilArray = oilProducts.slice(0,6);
  
  const accessoryProducts = categories['accessories'] || [];
  const sixAccessoryArray = accessoryProducts.slice(0,6);

  const fruitProducts = categories['fruits'] || [];
  const sixFruitArray = fruitProducts.slice(0,6);
  
  useEffect(() => {
      window.scroll(0, 0);
      // eslint-disable-next-line
    }, [history]);

  return (
    <div className="root">
      <Header className={classes.header} />
      <Alert variant="info" style={{backgroundColor: 'orange', color:'white'}}>Dear Esteemed customer, welcome to Adija Food Storage</Alert>
      <div className="main">
        
            <h2
                  style={{
                    fontSize: "48px",
                    margin: "5",
                    textAlign: "center",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
                >
              Our Products
            </h2>      
      <Container>
       <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
         <div className="product_an">
         <div className="productContainer">
        {
        transformProducts().map((prod)=> {
         return <SingleCategory 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
       </div>
       <div className="more">
        <Link to="/account/vegetables" style={{display:'flex', color: "hsl(218, 70%, 18%)", }}>
        <Typography variant="subtitle2">More vegetables</Typography>
        <ArrowRightAltIcon />
        </Link>
       </div>
       </div>
       </Grid>
       <Grid item xs={12} md={2}>
       </Grid>
       <Grid item xs={12} md={5}>
        <div className="product_an">
        <div className="productContainer">
        {
        sixGrainArray.map((prod)=> {
         return <SingleCategory 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
       </div>
       <div className="more">
        <Link to="/account/grains" style={{display:'flex', color: "hsl(218, 70%, 18%)", }}>
        <Typography variant="subtitle2">More grains</Typography>
        <ArrowRightAltIcon />
        </Link>
       </div>
      </div>
       </Grid>
      </Grid>
      <Grid container spacing={2}>
         <Grid item xs={12} md={5}>
      <div className="product_an">
      <div className="productContainer">
        {
        sixFishArray.map((prod)=> {
         return <SingleCategory 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
    
      </div>
      <div className="more">
        <Link to="/account/fishes" style={{display:'flex', color: "hsl(218, 70%, 18%)", }}>
        <Typography variant="subtitle2">More fishes</Typography>
        <ArrowRightAltIcon />
        </Link>
       </div>
       </div>
       </Grid>
       <Grid item xs={12} md={2}>
       </Grid>
       <Grid item xs={12} md={5}>
       <div className="product_an">
        <div className="productContainer">
        {
        sixOilArray.map((prod)=> {
         return <SingleCategory 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
      </div>
      <div className="more">
       <Link to="/account/oils" style={{display:'flex', color: "hsl(218, 70%, 18%)", }}>
        <Typography variant="subtitle2">More oils</Typography>
        <ArrowRightAltIcon />
       </Link>
       </div>
       </div>
       </Grid>
      </Grid>

      <Grid container spacing={2}>
         <Grid item xs={12} md={5}>
      <div className="product_an">
      <div className="productContainer">
        {
        sixFruitArray.map((prod)=> {
         return <SingleCategory 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
    
      </div>
      <div className="more">
       <Link to="/account/fruits" style={{display:'flex', color: "hsl(218, 70%, 18%)", }}>
        <Typography variant="subtitle2">More fruits</Typography>
        <ArrowRightAltIcon />
       </Link>
       </div>
       </div>
       </Grid>
       <Grid item xs={12} md={2}>
       </Grid>
       <Grid item xs={12} md={5}>
        <div className="product_an">
        <div className="productContainer">
        {
        sixAccessoryArray.map((prod)=> {
         return <SingleCategory 
                  prod={ prod } 
                  key= { prod._id }  
                 />
         })
       }
      </div>
      <div className="more">
       <Link to="/account/accessories" style={{display:'flex', color: "hsl(218, 70%, 18%)", }}>
        <Typography variant="subtitle2">More accessories</Typography>
        <ArrowRightAltIcon />
       </Link>
       </div>
       </div>
       </Grid>
      </Grid>

      </Container>
      </div>
      <SimpleBottomNavigation />
    </div>
    
  );
};

export default Products;

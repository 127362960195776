import React, { createContext, useContext, useReducer, useEffect, useState } from "react";
import axios from "axios";
import { ordReducer } from "./OrderReducers";
import { accessReducer } from "./OrderReducers";
import { AuthState } from "./AuthContext";


const Order = createContext();

const OrderContext = ({ children }) => {
  const [orderId, setOrderId]= useState();
  const [ordState, ordDispatch] = useReducer(ordReducer, {
    info: null,
    loading: false,
    error: null,
  });


const [accessState, accessDispatch] = useReducer(accessReducer, {
    info: [],
    loading: false,
    error: null,
    statuses:{},
  });

const { register, registerState, registerDispatch } = AuthState();
  const { user, loading, error } = registerState;



 // User actions
  async function store(items, address, deposited, txnref, pic) {
    console.log(items, address, deposited, txnref);
    try {
      ordDispatch({ type: "POST_ORDER_REQUEST" });

      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/orders",
        { items, address, deposited, txnref, pic },
        config
      );

      ordDispatch({ type: "POST_ORDER_SUCCESS", payload: data.message });
     
    } catch (error) {
    
      ordDispatch({
        type: "POST_ORDER_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }


  async function access() {
    try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get("/api/orders", config);

        const ordersArray = [];
        for (let i = 0; i < data.length; i++) {
            const orderList = data[i];

            for (let j = 0; j < orderList.productId.length; j++) {
                const product = orderList.productId[j];
                const _id = orderList._id;
                const prodId = product._id;
                const name = product.name;
                const price = orderList.price;
                const pic = product.pic;
                const qty = orderList.qty;
                const coords = orderList.location.coordinates;
                const status = orderList.status;

                for (let k = 0; k < orderList.users.length; k++) {
                    const user = orderList.users[k];
                    const firstname = user.firstname;
                    const lastname= user.lastname;
                    const email = user.email;
                    const createdDate = orderList.createdAt;

                    ordersArray.push({ _id, prodId, name, price, pic, qty, coords, status, firstname, lastname, email, createdDate });
                }
            }
        }

        accessDispatch({ type: "GET_ORDER_SUCCESS", payload: ordersArray});

        accessDispatch({ type: "GROUP_BY_STATUS", payload: ordersArray});
    } catch (error) {
        console.error(error);
    }
}

  
  useEffect(() => {
   access();
  }, [user]);

  return (
    <Order.Provider value={{ ordState, ordDispatch, store, accessState, accessDispatch, orderId, setOrderId}}>
      {children}
    </Order.Provider>
  );
}

export default OrderContext;

export const OrderState = () => {
  return useContext(Order);
}
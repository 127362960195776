import { Box, makeStyles } from "@material-ui/core";
import SingleChat from "./SingleChat";
import { ChatState } from "../Context/ChatProvider";

const useStyles = makeStyles((theme) => ({
  root: {
   display:'flex',
   alignItems:'center',
   flexDirection:'column',
   backgroundColor:'white'
  },
  image:{
    width:'72px',
    height:'90px',
    paddingRight:'20px'
  },
  about:{
   [theme.breakpoints.down('sm')]: {
      fontSize:'10px',
    },
   fontSize: '14px',
  },
}));

const Chatbox= ({ fetchAgain, setFetchAgain })=>{
     const classes= useStyles();
     const { selectedChat } = ChatState();
    return (
        <Box>
          <SingleChat className={classes.root} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
      </Box>
     );
};
export default Chatbox;
import React, { useState } from 'react';
import logo_img from './images/logo.png';
import { HashLink } from 'react-router-hash-link';
import 'font-awesome/css/font-awesome.min.css';
import './Navbar.css';
import {AuthState} from "../Context/AuthContext";
import CloseIcon from "@material-ui/icons/Close";
import DehazeIcon from "@material-ui/icons/Dehaze";

const Navbar = () => {
  const [isActive, setActive] = useState(false);

   const { login, loginState, loginDispatch } = AuthState();
  const { user, loading, error } = loginState;
  const handleClick = () => {
    setActive(!isActive);
  };

  const closeMobileMenu = () => {
    setActive(false);
  };
  return (
    <>
      <div className="nav-container">
        <div className="logo">
          <HashLink to="">
            <img src={logo_img} alt="storelogo" />
          </HashLink>
        </div>
        <div className={isActive ? 'active_links' : 'links'}>
          <div className="MenuItems">
            <HashLink to="/account" onClick={closeMobileMenu}>
              Products
            </HashLink>
          </div>
          <div className="MenuItems">
            <HashLink to="/account/welcome" onClick={closeMobileMenu}>
              About
            </HashLink>
          </div>
          {user ? ( <div className="MenuItems bgMenu" id="Appointment_menu">
            <div onClick={closeMobileMenu}>
              Logout
            </div>
          </div>
): (<>
          <div className="MenuItems">
            <HashLink to="/account/login" onClick={closeMobileMenu}>
              Login
            </HashLink>
          </div>
          <div className="MenuItems bgMenu" id="Appointment_menu">
            <HashLink to="/account/register" onClick={closeMobileMenu}>
              Create_an_account
            </HashLink>
          </div>
         </>)}
        </div>
        <div className="toggle_menu_icons" onClick={handleClick}>
          {isActive ? (<CloseIcon />): (<DehazeIcon />)}
        </div>
      </div>
    </>
  );
};

export default Navbar;

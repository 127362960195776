import {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Badge, Button, Typography } from "@material-ui/core";
import {CartState} from "../Context/Context";

const SingleCategory = ({ prod }) => {
 
  const { state: { cart },
     dispatch,
     productId,
     setProductId,
     selectedProduct,
     setSelectedProduct,
   } = CartState();
    
  const history= useHistory();

  const singleProd=() =>{

   history.push(`/account/product/${prod._id}`); 
  }
 
 
  return (
 <>
    <div
        onClick={() => singleProd()}
        className="cover cat_an"
        style={{ cursor: "pointer" }}
        color="inherit"
      >
      <img
        src={prod.pic}
        alt={prod.name}
      />
      <Typography variant="body2" style={{fontWeight:'400'}}>{prod.name}</Typography>
      <Typography variant="subtitle2" style={{
                    fontSize: "16px",
                    margin: "2px",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
> &#8358; {Number(prod.price).toLocaleString('en-US')}</Typography>
      </div>
</>
  );
};

export default SingleCategory;

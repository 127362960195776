import React,{ useState, useEffect, useRef } from 'react';
import axios from "axios";
import mapboxgl from "mapbox-gl";
import { Grid, Box, makeStyles, Paper, Button, Typography, MenuItem, InputLabel, FormControl, FormHelperText, TextField, Container } from '@material-ui/core';
import Select, {SelectChangeEvent} from "@material-ui/core/Select";
import Rating from "./Rating";
import {CartState} from "../Context/Context";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  root: {
   flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height:'90vh',
    backgroundColor:'#fff',
    [theme.breakpoints.down('sm')]: {
      height: '45vh',
    },
  },
  delivery:{
    padding: theme.spacing(1),
    height:'90vh',
    backgroundColor:'#fff',
    color: theme.palette.text.secondary,
  },
  location:{
    padding: theme.spacing(1),
    height:'32vh',
    backgroundColor:'#fff',
    color: theme.palette.text.secondary,
  },
  image: {
   width:'90%',
   height:'40%',
   [theme.breakpoints.down('sm')]: {
      height: '65%',
    },
  },

  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  box:{
   height:'100%',
 },
 hold:{
   height:'350px',
   position: 'relative',
   marginTop:'10px'
 },
 pickup:{
  position: 'absolute',
  backgroundColor: 'rgb(35 55 75 / 80%)',
  color: '#fff',
  zIndex:1,
  top:0,
  left:0,
  margin: '5px',
  borderRadius:'5px'
 },
}));

mapboxgl.accessToken = process.env.REACT_APP_MAPQUEST_KEY ;
const SingleProduct = ({single}) => {
  const classes = useStyles();
  
  const [video, setVideo] = useState();
  const [score, setScore] = useState('');
  const [opt, setOpt]= useState([]);
  const [selected, setSelected]= useState('');
  const inputComponent = useRef<HTMLInputElement>(null);
  const [position, setPosition] = useState(0);
  const { state: { cart },
     dispatch,
       } = CartState();
  
    const scoreData = ['Abia', 'Anambra', 'AkwaIbom', 'Bayelsa','Benue','Borno','Enugu','Kwara', 'Lagos', 'Nasarawa', 'Ondo','Ogun', 'Osun', 'Oyo', 'Rivers', 'Abuja'];
  const options = {
    'Abia': ['Umuahia'],
    'Anambra': ['Onitsha- Opiweka road'],
    'AkwaIbom': ['A K T C'],
    'Bayelsa': ['Yenagoa- Ekeki park'],
    'Benue': ['Markurdi- Otukpo'],
    'Borno': ['Maiduguri- Borno express'],
    'Enugu': ['Obolafo- 9th mile'],
    'Kwara': ['Ilorin'],
    'Lagos': ['Ijora- 7 up',],
    'Nasarawa': ['Lafia '],
    'Ondo': ['Akure- Shasha filling station',],
    'Ogun': ['Berger', 'Ogere',],
    'Osun': ['Ife- Ilesha by breweries',],
    'Oyo': ['Ibadan- Iwo road NNPC mega station',],
    'Rivers': ['Port Harcourt- Oil mill'],
    'Abuja': ['Nyanya'],
  };

    const handleChange = (event) => {
    
    setScore(event.target.value);
    setOpt(options[event.target.value] || []);
  };

  const handleSelect = (event) => {
    setSelected(event.target.value);
  };
  

 const cartItem = cart.find(item => item._id === single._id);

  return (
   <Box className={classes.root} justifyContent='center'>
    <Grid container spacing={2}>
      <Grid container item spacing={0} xs={12} md={9}>
      <Grid item xs={12} md={4} className={classes.imageContainer}>
       <div className={classes.paper}>
        <img className={classes.image} src={single.pic} alt="Product" />
        <Button
                    fullWidth
                    style={{marginTop:50, textAlign:'center'}}
                    variant="contained"
                    startIcon={<YouTubeIcon />}
                    color="secondary"
                    target="__blank"
                    href={`https://www.youtube.com/watch?v=${video}`}
                  >
                    Watch Product Quality
                  </Button>
       </div>

      </Grid>
      <Grid item xs={12} md={8}>
       <div className={classes.paper} sytle={{paddingLeft:5,}}>
          <Typography variant="h6">{single.name}</Typography>
          <Typography variant="h6" style={{marginTop: '10px'}}>Description</Typography>
          <Typography variant="body2" style={{
                    fontSize: "16px",
                    margin: "8px",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>{single.description}</Typography>

          <div>
           <Typography variant="body2">
             Brand: Adijastore | Similar products from Adijastore
           </Typography>
          </div>
          <div style={{marginTop:15}}>
            <Typography variant="h6" style={{
                    fontSize: "18px",
                    margin: "2px",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>&#8358;  {Number(single.price).toLocaleString('en-US')}</Typography>
          </div>
          <div style={{alignItems:'center'}}>
            <Typography variant="body2">
             <ErrorOutlineIcon fontSize='small' />
             50 units left
            </Typography>
          </div>
          <div>
           <Rating rating={single.ratings} />
           <span><Typography variant="body2">(50 verified reviews)</Typography></span>
          </div>
          {
                      cart.some(p=> p._id===single._id)? (
                       <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Button color="secondary" variant="contained" size="large"
                  
onClick={()=>{
         if(cartItem.qty > 1) {    
          dispatch({
              type: 'DECREASE_QUANTITY',
              payload:single,
          });
         }else{
          dispatch({
              type: 'REMOVE_FROM_CART',
              payload:single,
          });
         }

     }}
       >-</Button>

                        
           <span>{cartItem.qty}</span>
           <Button color="secondary" variant="contained" onClick={()=>{
           dispatch({
                   type: 'INCREASE_QUANTITY',
                   payload:single,
             });
       }}
       >+</Button>
           
          </Box>
                      ): (<Button color="primary" fullWidth size="large" variant="contained" onClick={()=>{
                             dispatch({
                                 type: 'ADD_TO_CART',
                                 payload:single,
                          });
                       }}
                          
                      disabled= {!single.inStock}>
                    {!single.inStock ? "Out of stock" : "Add to cart"}</Button>
)
                       
                  }
       </div>
      </Grid>
     </Grid> 
       <Grid item xs={12} md={3}>
         <div className={classes.delivery}>
           <Typography variant="subtitle2">DELIVERY & LOCATION</Typography>
           <Typography variant="subtitle2">Choose your location</Typography>
           <div>
            <div>
             <FormControl fullWidth={true}>
              {score ? <InputLabel id= "custom-select-label">Region</InputLabel> : ''}
               <Select 
                 variant="outlined"
                 labelId="custom-select-label"
                 id="custom-select"
                 value={score}
                 label={score ? "Score" : ""}
                 onChange={handleChange}
                 displayEmpty
                 renderValue={(value)=> value ? 
                  value: <Typography variant="subtitle2">Select region </Typography>
                 }
                 
                >
                  {scoreData.map((scoreValue)=>{
                   return <MenuItem value={scoreValue}>{scoreValue}</MenuItem>
                  })}
               </Select>
             </FormControl>
            </div>
            <div style={{marginTop:20}}>
             <FormControl fullWidth={true}>
              {score ? <InputLabel id= "custom-select-label">City</InputLabel> : ''}
               <Select 
                 variant="outlined"
                 labelId="custom-select-label"
                 id="custom-select"
                 value={selected}
                 label={score ? "Score" : ""}
                 onChange={handleSelect}
                 displayEmpty
                 renderValue={(value)=> value ? 
                  value: <Typography variant="subtitle2">Select city </Typography>
                 }
                 
                >
                  {opt.map((sel)=>{
                   return <MenuItem value={sel}>{sel}</MenuItem>
                  })}
               </Select>
             </FormControl>
            </div>
           
           </div>
           <div className={classes.hold}>
           <div>
           <Typography variant="subtitle2" style={{
                    fontSize: "16px",
                    margin: "5px",
                    lineHeight: "1.2",
                    color: "red",
                    
                  }}
>Check whether the product can be delivered to your address before making order</Typography>
            </div>
       
          </div>
         </div>
       </Grid>

    </Grid>
  </Box>
  );
};

export default SingleProduct;

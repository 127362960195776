import React, { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";
import { userLoginReducer } from "./AuthReducers";
import { userRegisterReducer } from "./AuthReducers";
import { userUpdateReducer } from "./AuthReducers";



const Auth = createContext();

const AuthContext = ({ children }) => {

  const [loginState, loginDispatch] = useReducer(userLoginReducer, {
    user: null,
    loading: false,
    error: null,
  });

  useEffect(()=> {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    
    if (user){
      loginDispatch({type: "USER_LOGIN_SUCCESS", payload: user });
    }
  }, []);

const [registerState, registerDispatch] = useReducer(userRegisterReducer, {
    user: null,
    loading: false,
    error: null,
  });

  const [updateState, updateDispatch] = useReducer(userUpdateReducer, {
    user: null,
    loading: false,
    error: null,
  });


 // User actions
  async function login(email, password) {
    console.log(email, password);
    try {
      loginDispatch({ type: "USER_LOGIN_REQUEST" });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users/login",
        { email, password },
        config
      );

      loginDispatch({ type: "USER_LOGIN_SUCCESS", payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      loginDispatch({
        type: "USER_LOGIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

  async function logout() {
    localStorage.removeItem("userInfo");
   loginDispatch({ type: "USER_LOGOUT" });
  }

  async function register(email, firstname, lastname, password, address, score, selected , phonenumber) {
    
    try {
      registerDispatch({ type: "USER_REGISTER_REQUEST" });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users",
        { email, firstname, lastname, password, address, score, selected, phonenumber },
        config
      );

      registerDispatch({ type: "USER_REGISTER_SUCCESS", payload: data });
      registerDispatch({ type: "USER_LOGIN_SUCCESS", payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      registerDispatch({
        type: "USER_REGISTER_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

  async function updateProfile(user) {
    try {
      updateDispatch({ type: "USER_UPDATE_REQUEST" });

      const {
        state: { user: userInfo },
      } = AuthState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post("/api/users/profile", user, config);

      updateDispatch({ type: "USER_UPDATE_SUCCESS", payload: data });
      updateDispatch({ type: "USER_LOGIN_SUCCESS", payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      updateDispatch({
        type: "USER_UPDATE_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

  return (
    <Auth.Provider value={{ loginState, loginDispatch, login, logout, register, updateProfile, updateState, registerState, registerDispatch, updateDispatch }}>
      {children}
    </Auth.Provider>
  );
}

export default AuthContext;

export const AuthState = () => {
  return useContext(Auth);
}
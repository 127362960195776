import React,{ useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import mapboxgl from "mapbox-gl";
import { Grid, Box, makeStyles, Paper, Button, Typography, MenuItem, InputLabel, FormControl, FormHelperText, TextField, Container } from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';

const useStyles = makeStyles((theme) => ({
  root: {
   flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height:'70vh',
    backgroundColor:'#fff',
  },
  image: {
   width:'100%',
   height:'40%',
  },
  
  box:{
   height:'100%',
 },
 hold:{
   height:'450px',
   position: 'relative',
   marginTop:'0'
 },

  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

mapboxgl.accessToken = process.env.REACT_APP_MAPQUEST_KEY ;
const TrackProduct = () => {
  const classes = useStyles();

  
  const inputComponent = useRef<HTMLInputElement>(null);
  const [order, setOrder] = useState();
  const [position, setPosition] = useState(0);
  const mapContainer = useRef(null);
  const map= useRef(null);
  const marker = useRef(null);
  const [lng, setLng]= useState(-70.9);
  const [lat, setLat]= useState(42.35);
  const [zoom, setZoom]= useState(9);

  useEffect(()=> {
    if(!map.current) {
    map.current= new mapboxgl.Map({
      container: 
        mapContainer.current,
        style:
         'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom
    });
   }
    if (order && order.length > 0 && order[0].location && order[0].location.coordinates) {
      const [lng, lat] = order[0].location.coordinates;
      map.current.setCenter([lng, lat]);

       if (!marker.current) {
        marker.current = new mapboxgl.Marker({
          element: createMarkerIcon(), // Custom marker icon
          anchor: 'bottom'
        })
          .setLngLat([lng, lat])
          .addTo(map.current);
      } else {
        marker.current.setLngLat([lng, lat]);
      }
       // Zoom to level 12
      map.current.setZoom(13);
    }
  }, [order]);
  
  const getOrder= async ()=>{
           try{
               const config= {
                  headers: {
                     "Content-type": "application/json",
                  },
               };
                const { data }= await axios.get(
                   "/api/orders",
                   config
                 );
            
                setOrder(data);
                console.log(data);
           } catch{
             
           }
       };
    useEffect(()=> {
         getOrder();
       }, []);
    
    const createMarkerIcon = () => {
  const icon = document.createElement('div');
  icon.style.width = '40px';
  icon.style.height = '40px';
  ReactDOM.render(<FlightIcon style={{ fontSize: '30px', color: 'green' }} />, icon);
  return icon;
};

  return (
   <div className={classes.hold}>
    <div ref={mapContainer} className={classes.box} />
   </div>  
  );
};

export default TrackProduct;
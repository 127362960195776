import React, { useState } from "react";
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import './Head.css';
import {
  Box,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Drawer,
  ListItem,
  ListItemText,
  List,
  makeStyles,
  TextField,
  Badge,
  Menu,
  MenuItem,
 Divider,
 useMediaQuery,
  createMuiTheme
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import CloseIcon from "@material-ui/icons/Close";
import HowToRegOutlinedIcon from "@material-ui/icons/HowToRegOutlined";

import {AuthState} from "../../Context/AuthContext";
import {CartState} from "../../Context/Context";

const useStyles = makeStyles((theme) => ({
  root:{
   boxShadow: '0px 1px 4px 0px #c8c8c8',
   padding: '10px',
   position: "sticky",
   top: 0,
   width: "100%",
   zIndex: 1000,
   backgroundColor: '#fff',
  },
  title: {
    justifyContent:'space-around',
    alignItems:'center',
  },
  tagline: {
    fontSize: 20,
    textTransform: "uppercase",
    justifyContent: "center",
    fontFamily: "Montserrat",
  },
  logo:{
   justifyContent: 'center',
   height: '60px',
   width: '100px',
  },
  about:{
   [theme.breakpoints.down('sm')]: {
      display:'none'
    },
   fontSize: '14px',
  },
  noreg:{
   [theme.breakpoints.down('sm')]: {
      display:'none'
    },
   
  },

}));

const Header = () => {
  const classes = useStyles();
  const smallscreen= useMediaQuery('(max-width:1400px)');
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu]= useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setActive] = useState(false);

  const { login, loginState, loginDispatch, logout } = AuthState();
  const { user, loading, error } = loginState;

 const handClick = () => {
    setActive(!isActive);
  };

  const closeMobileMenu = () => {
    setActive(false);
  };


  const {
     state: { cart },
     dispatch,
     productDispatch
  }= CartState();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  const handleOpenMenu = () =>{
     setOpenMenu(true);
  };
  const handleCloseMenu = () => {
     setOpenMenu(false);
  };
  const submitHandler= async (e)=>{
    e.preventDefault();
    loginDispatch(login(email, password));
    
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    loginDispatch(logout());
    handleClose();
  };

  return (
    <Box className={classes.root}>
         <Box>
          <Toolbar className={classes.title}>
            <div className={isActive ? 'active_paths' : 'paths'}>
          <div className="MenuItems">
            <Link to="/account" onClick={closeMobileMenu}>
              Products
            </Link>
          </div>
          <div className="MenuItems">
            <Link to="/account/welcome" onClick={closeMobileMenu}>
              About
            </Link>
          </div>
          {user ? (<div className="MenuItems bgMenu" id="Appointment_menu">
            <div onClick={closeMobileMenu}>
              Logout
            </div>
          </div>
 ) : (<>
          <div className="MenuItems">
            <Link to="/account/login" onClick={closeMobileMenu}>
              Login
            </Link>
          </div>
          <div className="MenuItems bgMenu" id="Appointment_menu">
            <Link to="/account/register" onClick={closeMobileMenu}>
              Register
            </Link>
          </div>
          </>)}
        </div>
        <div className="toggle_menu_icons" onClick={handClick}>
         {isActive === false ? (
          <DashboardIcon style={{ fontSize: 30, cursor: "pointer", color: "hsl(218, 70%, 18%)",}}
/>) : 
         (<CloseIcon style={{ fontSize: 35, cursor: "pointer", color: "hsl(218, 70%, 18%)",}}
 />)}
           </div>
            <img
              alt="adijastoreslogo"
              src={require("../../assets/logo.png")}
              className={classes.logo}
            />
          
              <Typography
               style={{ letterSpacing: 4, cursor: "pointer", color: "hsl(218, 70%, 18%)",}}
               className={classes.about}
              >
               Want to know about us?
             </Typography>
           {userInfo ? (
             <>
             <IconButton color="inherit" onClick={handleClick}>
               <HowToRegOutlinedIcon
                 style={{fontSize:'30px', letterSpacing: 4, cursor: "pointer", color: "hsl(218, 70%, 18%)",}}
                />
             </IconButton>
             <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
             >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            </>
            ): (<>
           <Link to="/account/login">
            <Button
              variant="outlined"
              color="primary"
              style={{  }}
            >
              Login
            </Button>
           </Link>

           <Link to="/account/register">
            <Button
              variant="contained"
              color="primary"
              className={classes.noreg}
            >
              Register
            </Button>
           </Link>
           </>
           )}
           <Link to="/account/notify">
            <NotificationsNoneOutlinedIcon 
              style={{ fontSize: 35, cursor: "pointer", color: "hsl(218, 70%, 18%)",}}
            />
           </Link> 
            <Link to="/account/cart">
            <ShoppingCartOutlinedIcon
              onClick={handleOpenMenu}
              style={{ fontSize: 35, cursor: "pointer", color: "hsl(218, 70%, 18%)",}}
            />
             <Badge
               badgeContent={cart.reduce((acc, curr)=> acc + curr.qty,0)}
               color="secondary"
             />
            </Link>
           
          </Toolbar>
  
          </Box>
  
    </Box>
  );
};

export default Header;
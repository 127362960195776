import {
  Button,
  TextField,
  Grid,
  Box, 
  makeStyles,
  Typography,
  Container,
  Divider,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SimpleBottomNavigation from "../../components/MainNav";
import Header from "../../components/Header/Header";



const useStyles = makeStyles((theme) => ({
  root: {
   flexGrow: 1,
   marginTop:'20px'
  },
  wrap:{
   backgroundColor:'#fff',
   padding:'10px',
   width:'80%',
  },
  cov:{
   display:'flex',
   alignItems:'center',
   justifyContent:'space-around',
   marginTop:'10px',
   color:'#000',
  },
  head:{
   marginBottom:'5px',
   color:'#000',
 
  },
  acc:{
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey',
   color:'#000',
   padding:'10px',
  },
  space:{
   width:'48%',
  },
  det:{
   borderRadius:'0 0 3px 3px',
   border:'1px solid grey',
   padding:'10px',
  },
  shape:{
   borderRadius:'0 0 3px 3px',
   border:'1px solid grey',
   padding:'10px',
   height:'108px',
  },
  tp:{
   marginTop:'15px',
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const Profile = () => {
 const classes = useStyles();

  return(
   <>
   <Header />
  
  <SimpleBottomNavigation />
 </>
  );
};

export default Profile;

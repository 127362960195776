export const cartReducer= (state, action) =>{
     switch(action.type){
        case "FETCH_DATA_SUCCESS":

      return {
        ...state, products: action.payload
      };

        case "ADD_TO_CART":
          let optionCart;
          if(Array.isArray(action.payload)){
           optionCart= [...state.cart, ...action.payload];
          }else{
           optionCart= [...state.cart, {...action.payload, qty:1}];
          }
          return{...state, cart:optionCart};
        case "REMOVE_FROM_CART":
          return{...state, 
                cart: state.cart.filter( (c)=> c._id!== action.payload._id), 
              };
         case "INCREASE_QUANTITY":
      return {
        ...state,
        cart: state.cart.map((item) =>
          item._id === action.payload._id
            ? { ...item, qty: item.qty + 1 }
            : item
        )
      };

    case "DECREASE_QUANTITY":
      return {
        ...state,
        cart: state.cart.map((item) =>
          item._id === action.payload._id && item.qty > 1
            ? { ...item, qty: item.qty - 1 }
            : item
        )
      };
         case "CHANGE_CART_QTY":
          return{...state, cart: state.cart.filter(c=>c._id === action.payload._id ? c.qty = action.payload.qty : c.qty),
          };
     case "GROUP_BY_CATEGORY":
       const categorizedProducts= action.payload.reduce((acc, product)=> {
         if(!acc[product.category]){
           acc[product.category] =[];
         }
         acc[product.category].push(product);
         return acc;
       },{}); 
       return { ...state, categories: categorizedProducts }; 

  default:
          return state;
    }
}

export const productReducer= (state, action) => {
    switch (action.type){
      case 'SORT_BY_PRICE': 
         return {...state, sort: action.payload};
      
       case 'FILTER_BY_STOCK': 
         return {...state, byStock: !state.byStock };

       case 'FILTER_BY_DELIVERY': 
         return {...state, byFastDelivery: !state.byStock };
 
       case 'FILTER_BY_RATING': 
         return {...state, byRating: action.payload };
        
       case 'FILTER_BY_SEARCH': 
         return {...state, searchQuery: action.payload };
     
       case 'CLEAR_FILTERS': 
         return {
             byStock: false,
         byFastDelivery: false,
         byRating: 0,
         searchQuery: "",
         };

      default:
          return state;
    }  
};

export const investReducer= (state, action) =>{
     switch(action.type){
        case "FETCH_INVEST_DATA":

      return {
        ...state, invests: action.payload
      };

        case "ADD_TO_STORE":
          return{...state, store:[...state.store, { ...action.payload, amt:1 }]};
        case "REMOVE_FROM_STORE":
          return{...state, 
                store: state.store.filter( (c)=> c._id!== action.payload._id), 
              };
         case "CHANGE_STORE_QTY":
          return{...state, store: state.store.filter(c=>c._id === action.payload._id ? c.amt = action.payload.amt : c.amt),
          };

  default:
          return state;
    }
};

export const storeReducer= (state, action) => {
    switch (action.type){
      case 'PULL_BY_PRICE': 
         return {...state, accord: action.payload};
      
       case 'PULL_BY_STOCK': 
         return {...state, onStock: !state.onStock };

       case 'PULL_BY_DELIVERY': 
         return {...state, onFastDelivery: !state.onFastDelivery };
 
       case 'PULL_BY_RATING': 
         return {...state, onRating: action.payload };
        
       case 'PULL_BY_SEARCH': 
         return {...state, seekQuery: action.payload };
     
       case 'CLEAR_PULLS': 
         return {
         onStock: false,
         onFastDelivery: false,
         onRating: 0,
         seekQuery: "",
         };

      default:
          return state;
    }  
};

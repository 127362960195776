import { makeStyles, Button, Typography } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {CartState} from "../Context/Context";
import Cookies from "universal-cookie";


const useStyles = makeStyles((theme) => ({
  root: {
   backgroundColor:'white',
   marginTop:'15px',
   padding:'16px',
  },
  top:{
   display:'flex',
   alignItems:'center',
   
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height:'90vh',
    backgroundColor:'#fff',
    [theme.breakpoints.down('sm')]: {
      height: '45vh',
    },
  },
  info:{
    paddingLeft:'16px',
    paddingRight:'16px'
  },
  image:{
    width:'72px',
    height:'72px',
  },
  text: {
    color:'#000',
  },
  bottom:{
   display:'flex',
   paddingTop:'8px',
   alignItems:'center'
  },
  arrange:{
   display:'inline-flex',
   marginRight:'auto',
   padding:0,
  },
}));

const CartList = ({ prod }) => {
  const classes = useStyles();
  const cookies = new Cookies();


  const { state: { cart }, dispatch } = CartState();

  const cartItem = cart.find(item => item._id === prod._id);

  const handleRemoveFromCart = (item) => {
    dispatch({
      type: 'REMOVE_FROM_CART',
      payload: item,
    });
    // Update the cookies after removing the item from the cart
    cookies.set('cartState', cart.filter(c => c._id !== item._id), { path: '/' });
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <img className={classes.image} src={prod.pic} alt="Product" />
        <div className={classes.info}>
          <Typography variant="subtitle2" className={classes.text}>{prod.name}</Typography>
          <Typography variant="subtitle2" className={classes.text} style={{
                    fontSize: "18px",
                    margin: "10",
                    lineHeight: "1.2",
                    color: "hsl(218, 70%, 18%)",
                    
                  }}
>&#8358; {Number(prod.price).toLocaleString('en-US')}</Typography>

          <Typography variant="body2" className={classes.text}>Few units left</Typography>
        </div>
      </div>
      <div className={classes.bottom}>
        <Button onClick={() => handleRemoveFromCart(prod)}>
          <DeleteForeverIcon color="primary" />
          REMOVE
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            if (cartItem.qty > 1) {
              dispatch({
                type: 'DECREASE_QUANTITY',
                payload: prod,
              });
            } else {
              handleRemoveFromCart(prod);
            }
          }}
        >-</Button>
        <span className={classes.text}>{cartItem.qty}</span>
        <Button color="secondary" variant="contained" onClick={() => {
          dispatch({
            type: 'INCREASE_QUANTITY',
            payload: prod,
          });
        }}>+</Button>
       </div>
    </div>
  );
};

export default CartList;
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MailIcon from "@material-ui/icons/Mail";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { useHistory } from "react-router-dom";
import {CartState} from "../Context/Context";


const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left:0,
    backgroundColor: "#2d313a",
    zIndex: 100,
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const { value, setValue} = CartState();
  const history = useHistory();
  
  useEffect(() => {
    if (value === 0) {
      history.push("/account");
    } else if (value === 1) {
      history.push("/account/orders");
    }
  }, [value, history]);
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        style={{ color: "white" }}
        label="Buy a Product"
        icon={<ShoppingBasketIcon />}
      />
      <BottomNavigationAction
        style={{ color: "white" }}
        label="Orders"
        icon={<LocalShippingIcon />}
      />

    </BottomNavigation>
  );
}


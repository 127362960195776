import React,{ useState, useEffect } from 'react';
import { makeStyles, } from '@material-ui/core';
import { FormControl, MenuItem,} from '@material-ui/core';
import "./Register.css";
import Spinner from '../components/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select, {SelectChangeEvent} from "@material-ui/core/Select";
import { Link, useHistory } from 'react-router-dom';
import { AuthState } from "../Context/AuthContext";
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-nothedOutline': {
     borderColor: 'blue',
    },
    '&:hover .MuiOutlinedInput-nothedOutline ':{
     borderColor:'grey'
    },
  },
  but:{
   marginTop:'25px'
  },
  bt:{
   marginTop:'15px'
  },
  opt:{
    display:'flex',
    alignItems:'center',
    margin: '20px 0px',
    },
  con:{
   textAlign:'center',
  },
  select: {
    backgroundColor: 'transparent',
    border: '0.1rem solid #4e0eff',
    borderRadius: '0.4rem',
    color: 'white',
    width: '100%',
    fontSize: '1rem',
    '& .MuiSelect-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4e0eff',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#997af0',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  check: {
    backgroundColor: 'transparent',
    border: '0.1rem solid #4e0eff',
    borderRadius: '0.4rem',
    color: 'white',
    
    '& .MuiSelect-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4e0eff',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#997af0',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
   formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
   inputLabel: {
    color: 'white',
    '&.Mui-focused': {
      color: '#997af0',
    },
  },
  input: {
    display: 'none',
  },

}));

export default function RegisterPage() {
  const classes = useStyles();
  const history = useHistory();
     const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phonenumber, setPhonenumber]= useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [score, setScore] = useState('');
  const [opt, setOpt]= useState([]);
  const [selected, setSelected]= useState('');
  const [loader, setLoader] = useState('flex');

  const { register, registerState, registerDispatch } = AuthState();
  const { user, loading, error } = registerState;

  const scoreData = ['Abia', 'Anambra', 'AkwaIbom', 'Bayelsa','Benue','Borno','Enugu','Kwara', 'Lagos', 'Nasarawa', 'Ondo','Ogun', 'Osun', 'Oyo', 'Rivers', 'Abuja'];
  const options = {
    'Abia': ['Umuahia'],
    'AkwaIbom': ['A K T C'],
    'Anambra': ['Onitsha- Opiweka road'],
    'Bayelsa': ['Yenagoa- Ekeki park'],
    'Benue': ['Markurdi- Otukpo'],
    'Borno': ['Maiduguri- Borno express'],
    'Enugu': ['Obolafo- 9th mile'],
    'Kwara': ['Ilorin'],
    'Lagos': ['Ijora- 7 up',],
    'Nasarawa': ['Lafia'],
    'Ondo': ['Akure- Shasha filling station',],
    'Ogun': ['Berger', 'Ogere',],
    'Osun': ['Ife- Ilesha by breweries',],
    'Oyo': ['Ibadan- Iwo road NNPC mega station',],
    'Rivers': ['Port Harcourt- Oil mill'],
    'Abuja': ['Nyanya'],
  };

  const handleChange = (event) => {
    
    setScore(event.target.value);
    setOpt(options[event.target.value] || []);
  };

  const handleSelect = (event) => {
    setSelected(event.target.value);
  };

 const toastOptions = {
    position: "top-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

 const handleValidation = () => {
    if (password !== confirmpassword) {
      toast.error(
        "password and confirm password should be same.",
        toastOptions
      );
      return false;
    } else if (password.length < 6) {
      toast.error("Password should be greater than 6 character", toastOptions);
      return false;
    } else if (email === "") {
      toast.error("Email is required", toastOptions);
      return false;
    }
    return true;
  };


  const submitHandler= async (e)=>{
    e.preventDefault();
    if (handleValidation()) {
    registerDispatch(register(email, firstname, lastname, password, address, score, selected, phonenumber )); 
    }
  };

  useEffect(()=> {
   if(user){
   const redirectPath = localStorage.getItem('redirectPath') || '/account';
     history.push(redirectPath);
     localStorage.removeItem('redirectPath');
    }
  },[user]);

  return (
    <>
     <div className="register_form_section">
        <div className="form_container_register">

          <form
            className="register_u_form"
            onSubmit={submitHandler}
            data-aos="fade-right"
          >
            <div className="brand">
              <img src={require("../assets/storelogo.jpg")} alt="logo" />
              <h1>ADIJA FOOD</h1>
            </div>
            <input
              type="text"
              placeholder="Enter Your First Name"
              name="name"
              value={firstname}
      onChange= {(e)=> setFirstName(e.target.value)}
              autoComplete="off"
            />
            <input
              type="text"
              placeholder="Enter Your Last Name"
              name="lastname"
              value={lastname}
      onChange= {(e)=> setLastName(e.target.value)}
              autoComplete="off"
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange= {(e)=> setEmail(e.target.value)}
              autoComplete="off"
            />
            <input
              type="Password"
              placeholder="Password"
              name="password"
              value={password}
              onChange= {(e)=> setPassword(e.target.value)}
              autoComplete="off"
            />
            <input
              type="Password"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={confirmpassword}
              onChange= {(e)=> setConfirmPassword(e.target.value)}
              autoComplete="off"
            />
            <input
              type="text"
              placeholder="Enter Your Delivery Address"
              name="address"
              value={address}
      onChange= {(e)=> setAddress(e.target.value)}
              autoComplete="off"
            />
           
              <FormControl fullWidth className={classes.formControl}>
            {score && <InputLabel id="custom-select-label" className={classes.inputLabel}>Region</InputLabel>}
            <Select
              variant="outlined"
              labelId="custom-select-label"
              id="custom-select"
              value={score}
              label="Region"
              onChange={handleChange}
              displayEmpty
              className={classes.select}
              renderValue={(value) => value || <Typography variant="subtitle2">Select region</Typography>}
            >
              {scoreData.map((scoreValue) => (
                <MenuItem key={scoreValue} value={scoreValue}>{scoreValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            {selected && <InputLabel id="custom-select-label" className={classes.inputLabel}>City</InputLabel>}
            <Select
              variant="outlined"
              labelId="custom-select-label"
              id="custom-select"
              value={selected}
              label="City"
              onChange={handleSelect}
              displayEmpty
              className={classes.select}
              renderValue={(value) => value || <Typography variant="subtitle2">Select city</Typography>}
            >
              {opt.map((sel) => (
                <MenuItem key={sel} value={sel}>{sel}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <input
              type="text"
              placeholder="Enter Your Phone Number"
              name="phonenumber"
              value={phonenumber}
      onChange= {(e)=> setPhonenumber(e.target.value)}
              autoComplete="off"
            />
            <span className="lower_title_register">
              <Checkbox className={classes.check} color='secondary' />
<Link to="#">Accept terms and conditions</Link>
            </span>

            <button className="submit_register_btn" type="submit">
              Sign Up
              {loading && <Spinner id="login_loder" style={loader} />}
            </button>
            <span className="lower_title_register">
              Already have an account ?<Link to="/account/login">Login</Link>
            </span>
          </form>
      </div>
      {error && toast.error(error, toastOptions) }
       <ToastContainer />
      </div>

    </>
  );
}

import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { CartState } from "../Context/Context";
import { Button, Container, Grid, makeStyles, Typography, Hidden, Box, Radio } from "@material-ui/core";
import Rating  from "../components/Rating";
import OrderList from "../components/OrderList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
   minHeight: '100vh',

  },
  container: {
   display: 'flex',
   flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height:'90vh',
    backgroundColor:'#fff',
    [theme.breakpoints.down('sm')]: {
      height: '45vh',
    },
  },
  check: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      backgroundColor:'#fff',
    },
  },
   text:{
    color:'#000',
   },
  info:{
    display: 'flex',
    flexDirection: 'column',
  },
  tot:{
   backgroundColor:'white',
   color:'black',
   display:'flex',
   justifyContent:'space-between',
   marginTop:'10px',
  },
  hide:{
   paddingLeft:'20px',
   paddingRight:'20px',
   width:'100%'
  },
  hd:{
   display:'flex',
   justifyContent: 'space-between'
  },
  change:{
   color:'blue'
  },
  addr:{
   backgroundColor:'#fff',
   borderRadius:'5px',
   color:'#000', 
   padding:'5px',
   marginBottom:'10px',
  },
  sel:{
   display:'flex'
  },
  pick:{
   display:'flex',
   justifyContent:'space-between',
   padding:'10px',
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey'
  },
  point:{
   padding:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },
  ship:{
   borderRadius:'5px 5px 0 0',
   border:'1px solid grey',
   padding:'10px'
  },
  orderlist:{
   display:'flex',
   flexDirection:'column',
   paddingLeft:'10px',
   borderRadius:'0 0 5px 5px',
   border:'1px solid grey'
  },
  image:{
   width:'100px',
   height:'30px',
  },

}));

const PayMethod = ( ) =>{
      const classes = useStyles();
      const history= useHistory();

      useEffect(() => {
      window.scroll(0, 0);
      // eslint-disable-next-line
    }, [history]); 

      const { state: { cart },
        dispatch,
        paystack,
        setPaystack,
        direct,
        setDirect
      } = CartState();

       const[ total, setTotal] = useState();

       useEffect(() => {
         setTotal(cart.reduce((acc, curr)=> acc + Number(curr.price) * curr.qty,0));
       }, [cart]);
      return  <div className={classes.root}>
                <Container>
                 <Hidden smUp={true}>
                  <Link style={{display:'flex', padding: '10px', alignItems:'center'}} to="/account/delivery">
                  <ArrowBackIcon />
                  <Typography variant="subtitle1">Select payment method</Typography>
                 </Link>
                  <Typography>
                    Cart Summary
                  </Typography>
                  <Box className={classes.tot}>
                    <Typography>
                     Subtotal
                    </Typography>
                    <Typography>
                      &#8358;  {Number(total).toLocaleString('en-US')}
                    </Typography>
                  </Box>
                 </Hidden>
                 <Grid container spacing={5}>
                   <Grid item xs={12} md={8}>
                      <Typography variant="subtitle2" style={{marginTop:'10px',}}>PAYMENT METHOD</Typography>
                      <Box className={classes.addr}>
                       <Box className={classes.sel} onClick={()=> {setPaystack(false); setDirect(true);}}>
                        <Radio checked={direct} />
                        <Box className={classes.text}>
                         <Typography variant="subtitle2">
                           Pay with Bank Transfer or USSD
                         </Typography>
                         <Typography variant="body2">You will be redirected to our secure checkout page</Typography>
                        </Box>
                       </Box>
                       <Box className={classes.pick}>
                        <Typography variant="body1">We accept:</Typography>
                        <img 
                          className={classes.image} 
                  
          alt="Paymethod"
                        />

                       </Box>
                       <Box className={classes.point}>
                        <Typography variant="body2">-Kindly note that you will be make payment to our bank account...</Typography>
                        <Typography variant="body2">Details</Typography>
                       </Box>
                      

                      </Box>
                      

                    </Grid>
                    <Hidden smUp={true}>
                     <Box className={classes.hide}>
                      <Link to="/account/summary">
                        <Button variant="contained" color="primary" style={{width:'100%', marginBottom:'30px' }} disabled={cart.length === 0}>Confirm method</Button>
                      </Link>

                     </Box>
                    </Hidden>
                    <Grid item xs={12} md={4} className={classes.check}>
                       <div>
                       <h3 className={classes.text}>CART SUMMARY</h3>
                        <span className={classes.text}>
                          Subtotal ({cart.length}) items</span>
                        <span className={classes.text} style={{ fontweight: 700, fontSize: 20}}> &#8358;  {Number(total).toLocaleString('en-US')}</span>
                       <div>
                        <Link to="/account/summary">
                        <Button variant="contained" color="primary" disabled={cart.length === 0}>Confirm method</Button>
                         </Link>
                        </div>
                       </div>
                    </Grid>
          </Grid>
          </Container>
         </div>
   
    };

export default PayMethod;

import { Box, Typography, TextField, LinearProgress, FormControl, Alert, makeStyles } from "@material-ui/core";
import { getSender, getSenderFull } from "../config/ChatLogics";
import { useEffect, useState } from "react";
import axios from "axios";
import ScrollableChat from "./ScrollableChat";
import animationData from "../animations/typing.json";
import io from "socket.io-client";
import { ChatState } from "../Context/ChatProvider";

const ENDPOINT = "http://localhost:5000";

var socket, selectedChatCompare;

const useStyles = makeStyles((theme) => ({
  root: {
   display:'flex',
   justifyContent:'flex-end',
   flexDirection:'column',
   backgroundColor:'white',
   padding:'10px',
   position:'relative',
  },
  offline:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height:'100%',
    backgroundColor:'white',
    color:'black',
  },
  msg:{
   position: "fixed",
    bottom: 55,
    left:10,
    right:10,
    backgroundColor: "#fff",
    zIndex: 100,
  
 },
 all:{
  minHeight:'100vh',
  position:'relative'
 },
  about:{
   [theme.breakpoints.down('sm')]: {
      fontSize:'10px',
    },
   fontSize: '14px',
  },
}));

const SingleChat= ({ fetchAgain, setFetchAgain }) =>{
    const classes= useStyles();
    const [messages, setMessages ] = useState([]);
     const [loading, setLoading] = useState(false);
     const [ newMessage, setNewMessage]= useState("");
      const [socketConnected, setSocketConnected] = useState(false);
      const [typing, setTyping]= useState(false);
      const [isTyping, setIsTyping] = useState(false);
       const { selectedChat, setSelectedChat, client, notification, setNotification }= ChatState();
       
       const fetchMessages= async ()=>{
           if (!selectedChat) return;
           try{
               const config= {
                  headers: {
                     Authorization: `Bearer ${client.token}`,
                  },
               };
                setLoading(true);
                const { data }= await axios.get(
                   `/api/message/user/${selectedChat._id}`,
                   config
                 );
            
                setMessages(data);
                setLoading(false);
                console.log(messages);
                socket.emit("join chat", selectedChat._id);
           } catch{
             
           }
       };
      const sendMessage= async(event)=>{
          if (event.key === "Enter" && newMessage){
             socket.emit("stop typing", selectedChat._id);
             try{
                const config= {
                   headers: {
                       "Content-type": "application/json",
                        Authorization: `Bearer ${client.token}`,
                   },
                 };
                setNewMessage("");
                 const { data } = await axios.post(
                     "api/message/user",
                    {
                         content: newMessage,
                         chatId: selectedChat._id,
                    },
                  config
                );

               socket.emit("new message", data);
               setMessages([...messages, data]);
             } catch(error) {
                 
             }
          }
       };
      useEffect(()=> {
           socket= io(ENDPOINT);
           socket.emit("setup", client);
            socket.on("connected", ()=> setSocketConnected(true));
            socket.on("stop typing", ()=> setIsTyping(false));
      }, [selectedChat]);
  
       useEffect(()=> {
         fetchMessages();
        selectedChatCompare= selectedChat;
       }, [selectedChat]);

       useEffect(()=> {
            socket.on("message received", (newMessageReceived)=> {
               if(
                  !selectedChatCompare || selectedChatCompare._id !== newMessageReceived.chat._id
               ){
                    if(!notification.includes(newMessageReceived)){
                       setNotification([newMessageReceived, ...notification]);
                       setFetchAgain(!fetchAgain);
                  }
              } else{
                     setMessages([...messages, newMessageReceived]);
                }
            });
      });
      const typingHandler = (e)=>{
          setNewMessage(e.target.value);
           if(!socketConnected) return;
           if(!typing){
               setTyping(true);
               socket.emit("typing", selectedChat._id);
           }
          let lastTypingTime= new Date().getTime();
          var timerLength= 3000;
          setTimeout(()=> {
              var timeNow= new Date().getTime();
              var timeDiff= timeNow - lastTypingTime;
              if (timeDiff >= timerLength && typing){
                 socket.emit("stop typing", selectedChat._id);
                 setTyping(false);
              }
          }, timerLength);
     };
     return (
         <>
             {selectedChat ?  (
                 <>
                   <Box
                       className={classes.root}
                    >
                      {loading ? (
                          <LinearProgress />
                      ) : (
                     
                      <div className={classes.all}>
                       <ScrollableChat messages={messages} />
                      <div className={classes.msg}>

                      <FormControl
                        onKeyDown={sendMessage}
                        id="first-name"
                       isRequired
                        fullWidth
                       Style={{marginTop:'6px'}}
                    >
                        {isTyping ? (
                              <div>
                                
                              </div>
                        ): (
                          <></>
                        )}
                     
                       <TextField
                         variant="filled"
                         bg="#E0E0E0"
                         placeholder="Enter a message.."
                         onChange={typingHandler}
                         value={newMessage}
                         fullWidth
                       />
                     
                  </FormControl>
                   </div>
                   </div>

                      )} 
                       </Box>
             </>
             ) : (
               <Box className={classes.offline}>
                    <Typography>
                      You are not logged in...
                   </Typography>
               </Box>
             )} 
         </>
     );
};
export default SingleChat;
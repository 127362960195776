import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import SimpleBottomNavigation from "./components/MainNav";
import Movies from "./Pages/Movies/Movies";
import Series from "./Pages/Series/Series";
import Trending from "./Pages/Trending/Trending";
import Grain from "./Pages/Trending/Grain";
import Fish from "./Pages/Trending/Fish";
import Oil from "./Pages/Trending/Oil";
import Accessory from "./Pages/Trending/Accessory";
import Fruit from "./Pages/Trending/Fruit";
import Products from "./Pages/Products/Products";
import Search from "./Pages/Search/Search";
import CoinPage from "./Pages/CoinPage";
import ChatPage from "./Pages/ChatPage";
import CartPage from "./Pages/CartPage";
import CheckOutPage from "./Pages/CheckOutPage";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import WelcomePage from "./Pages/WelcomePage";
import ProductDetail from "./Pages/ProductDetail";
import TrackProduct from "./Pages/TrackProduct";
import Notifications from "./Pages/Notifications";
import OrderDetails from "./Pages/OrderDetails";
import Delivery from "./Pages/Delivery";
import PayMethod from "./Pages/PayMethod";
import Summary from "./Pages/Summary";
import Context from "./Context/Context";
import CryptoContext from "./Context/CryptoContext";
import ChatProvider from "./Context/ChatProvider";
import AuthContext from "./Context/AuthContext";
import OrderContext from "./Context/OrderContext";


function App() {
  return (
<AuthContext>
 <ChatProvider>
   <CryptoContext>
    <Context>
     <OrderContext>
     <BrowserRouter>
      <div>
          <Switch>
            <Route path="/account/" component={Products} exact />
            <Route path="/account/invest" component={Movies} />
            <Route path="/account/orders" component={Series} />
            <Route path="/account/profile" component={Search} />
            <Route path="/account/coins/:id" component={CoinPage} />
            <Route path="/account/support" component={ChatPage} />
            <Route path="/account/cart" component={CartPage} />
            <Route path="/account/checkout" component={CheckOutPage} />
            <Route path="/account/login" component={LoginPage} />
            <Route path="/account/register" component={RegisterPage} />
            <Route path="/account/product/:_id" component={ProductDetail} />
            <Route path="/account/welcome" component={WelcomePage} />
            <Route path="/account/vegetables" component={Trending} />
            <Route path="/account/grains" component={Grain} />
             <Route path="/account/fishes" component={Fish} />
             <Route path="/account/vegetables" component={Trending} />
             <Route path="/account/oils" component={Oil} />
             <Route path="/account/accessories" component={Accessory} />
             <Route path="/account/fruits" component={Fruit} />

            <Route path="/account/details" component={OrderDetails} />
            <Route path="/account/track" component={TrackProduct} />
            <Route path="/account/delivery" component={Delivery} />
            <Route path="/account/pay-method" component={PayMethod} />
            <Route path="/account/summary" component={Summary} />
            <Route path="/account/notify" component={Notifications} />
          </Switch>
      </div>
      
    </BrowserRouter>
   </OrderContext>
  </Context>
 </CryptoContext>
</ChatProvider>
</AuthContext>
  );
}

export default App;

import { makeStyles, Button, Typography } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {CartState} from "../Context/Context";


const useStyles = makeStyles((theme) => ({
  root: {
   backgroundColor:'white',
   padding:'16px',
 
  },
  top:{
   display:'flex',
   alignItems:'center',
   
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height:'90vh',
    backgroundColor:'#fff',
    [theme.breakpoints.down('sm')]: {
      height: '45vh',
    },
  },
  info:{
    paddingLeft:'16px',
    paddingRight:'16px'
  },
  image:{
    width:'40px',
    height:'40px',
  },
  text: {
    color:'#000',
  },
  bottom:{
   display:'flex',
   paddingTop:'8px',
   alignItems:'center'
  },
  arrange:{
   display:'inline-flex',
   marginRight:'auto',
   padding:0,
  },
}));

const OrderList =({ prod }) =>{
   const classes = useStyles();

   const { state: { cart },
     dispatch,
   } = CartState();

   const cartItem = cart.find(item => item._id === prod._id);
   return (
     <div className={classes.root}>
           <div className={classes.top}>
                <img className={classes.image} src={prod.pic} alt="Product" />
      <div className={classes.info}>
        <Typography variant="body2">{prod.name}</Typography>
        <Typography variant="body2">QTY:{prod.qty}</Typography>
      </div>
      </div>
      
     </div>
   );
}
export default OrderList;
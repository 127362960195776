export const ordReducer = (state, action)  =>{
  switch (action.type) {
    case "POST_ORDER_REQUEST":
      return { ...state, loading: true };
    case "POST_ORDER_SUCCESS":
      return { ...state, loading: false, info: action.payload };
    case "POST_ORDER_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "USER_LOGOUT":
      return { ...state, user: null };
    default:
      return state;
  }
}

// get order reducer
export const accessReducer = (state, action) => {
  switch (action.type) {
    case "GET_ORDER_REQUEST":
      return { ...state, loading: true };
    case "GET_ORDER_SUCCESS":
      return { ...state, loading: false, info: action.payload };
    case "GET_ORDER_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "GROUP_BY_STATUS":
       const orderStatus= action.payload.reduce((acc, stat)=> {
         if(!acc[stat.status]){
           acc[stat.status] =[];
         }
         acc[stat.status].push(stat);
         return acc;
       },{}); 
       return { ...state, statuses: orderStatus }; 

    default:
      return state;
  }
}
import Navbar from './Navbar';
import Home from './Home';
import About from './About';
import Appointment from './Appointment';
import Services from './Services';
import Contact from './Contact';

function WelcomePage() {
  return (
    <div>
     <Navbar />
     <Home />
     <About />
     <Services />
     <Contact />
    </div>
  );
}

export default WelcomePage;

import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const ProductDetail = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} className={classes.imageContainer}>
        {/* Your image component */}
        <img src="your-image-url" alt="Product" />
      </Grid>
      <Grid item xs={12} md={6}>
        {/* Details about the product */}
        <div>
          {/* Product title, price, etc. */}
          <h2>Product Title</h2>
          <p>Price: $100</p>
          {/* Other product details */}
        </div>
      </Grid>
    </Grid>
  );
};

export default ProductDetail;